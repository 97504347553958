import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import BannerPage from '../../../components/BannerPage';
import Footer from '../../../components/Footer';
import '../../../App.css';
import ruesData from '../../../data/rues.json';
import monumentsData from '../../../data/monuments.json';

const Visitandines = () => {
  const navigate = useNavigate();
  
  const handlePageClick = (link) => {
    navigate(link);
  };

  // Filtrer le tableau ruesData pour récupérer uniquement l'élément correspondant à "Bert"
  const trinquetPage = ruesData.find((page) => page.name === "Trinquet");
  const neufPage = monumentsData.find((page) => page.name === "Château Neuf");

  return (
    <div>
      <BannerPage>
        <div>
          <h2>Visitandines</h2>
          <h3>Histoire et photos des rues, monuments et ponts du petit Bayonne</h3>
        </div>
      </BannerPage>
      <div className="riverain-container">
        <div className="image-container2">
          <img src="https://mediatheque.bayonne.fr/iguana/uploads/image/PATRIMOINE/EXPOSITION%20VIRTUELLE/2020/VISITANDINES/B641026201_WM302-1B_0371_ext.jpg" alt="Plan du couvent des visitandines" className="riverain-image" />
          <figcaption>Plan du couvent des visitandines</figcaption>
        </div>
        <div className='apropos-content'>
          <h4>Rue des VISITANDINES</h4>
          <p className='histoire'>
          Ancien nom : <strong>rue de la Visitation</strong><br /> <br />
          Changement de numérotation en 1855.<br />   <br />
          Sur le Couvent des Visitations.<br /> <br />
          En 1830, la rue de la Visitation est une amorce percée destinée à relier la rue Bourgneuf à la rue Pannecau. Cette continuité n’a pas eu lieu.
          <br /><br />
          Depuis 1700 jusqu’au 19e siècle : on y trouve une caserne de gendarmerie et les bains des visitations sur l’ancien cloître. Des restes de piliers et de murs sont visible dans le restaurant Kalosrape rue Marengo.
          </p>
        </div>
      </div>
      <div className="navigation-buttons">
        {/* Afficher le bouton seulement si l'élément correspondant existe */}
        {trinquetPage && neufPage && (
          <div className="riverain-buttons">
            <div className="riverain-item" onClick={() => handlePageClick(trinquetPage.link)}>
              <Link to={trinquetPage.link}>Page précédente</Link>
            </div>
            <div className="riverain-item" onClick={() => handlePageClick(neufPage.link)}>
              <Link to={neufPage.link}>Page suivante</Link>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};
export default Visitandines;