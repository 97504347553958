import React from 'react';

const BannerPage = ({ children }) => {
  return (
    <div className="banner">
      {children}
      <style jsx>{`
        .banner {
          background-color: #da8b2a;
          padding: 4rem;
          text-align: center;
          color: white;
        }
      `}</style>
    </div>
  );
};

export default BannerPage;
