import React from 'react';
import BannerPage from '../components/BannerPage';
import '../App.css';
import Footer from '../components/Footer';
import { saveAs } from 'file-saver';
import riverainsData from '../data/alpha.json';

const ClassementAlpha = () => {
  const handleRiverainClick = (pdf) => {
    saveAs(pdf);
  };

  return (
    <div>
      <BannerPage>
        <div>
          <h2>Liste des riverains par ordre alphabétique</h2>
        </div>
      </BannerPage>
      <div className="riverain-container">
      <h3>Pdf à télécharger en cliquant sur le nom des rues.</h3>
        <div className="image-container2">
          <img src="https://www.bayonne.fr/fileadmin/_processed_/3/a/csm_4_tram_bus_T2___M.Prat_523798143d.jpg" alt="Mairie de Bayonne" className="riverain-image" />
          <figcaption>Mairie de Bayonne</figcaption>
        </div>
        <div className="riverain-buttons">
          {riverainsData.map((riverain, index) => (
            <div key={index} className="riverain-item" onClick={() => handleRiverainClick(require(`../pdf/${riverain.pdf}`))}>
              {riverain.name}
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ClassementAlpha;


