import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import BannerPage from '../../../components/BannerPage';
import Footer from '../../../components/Footer';
import '../../../App.css';
import monumentsData from '../../../data/monuments.json';

const Genie = () => {
  const navigate = useNavigate();
  
  const handlePageClick = (link) => {
    navigate(link);
  };

  // Filtrer le tableau ruesData pour récupérer uniquement l'élément correspondant à "Bert"
  const esplanadeBarthesPage = monumentsData.find((page) => page.name === "Esplanade Roland Barthe");
  const pontMarengoPage = monumentsData.find((page) => page.name === "Pont Marengo");
  
  return (
    <div>
      <BannerPage>
        <div>
          <h2>Pont du Génie</h2>
          <h3>Histoire et photos des rues, monuments et ponts du petit Bayonne</h3>
        </div>
      </BannerPage>
      <div className="riverain-container">
        <div className="image-container2">
          <img src="https://files.structurae.net/files/photos/5256/2017-08-07/dsc04568.jpg" alt="Pont du Génie" className="riverain-image" />
          <figcaption>Pont du Génie</figcaption>
        </div>
        <div className='apropos-content'>
          <h4>Pont du Génie</h4>
          <p className='histoire'>
          Le pont du Génie, ou pont Courtine, a remplacé l’ancienne estacade qui barrait l’entrée de la Nive.<br /> <br />
          Le premier pont en bois date de 1799, puis le génie militaire bâti en lieu et place un pont en pierre à trois arches.<br />   <br />
          A proximité des ponts du Génie, Mayou, Marengo et Pannecau, les berges sont aménagées en gradins. Ces différents niveaux en escaliers permettent ainsi le transbordement des marchandises quel que soit la marée.
          Les quais à gradins disparaîtront en 1920 afin de faciliter la circulation.<br /><br />
          </p>
        </div>
      </div>
      <div className="navigation-buttons">
        {/* Afficher le bouton seulement si l'élément correspondant existe */}
        {esplanadeBarthesPage && pontMarengoPage && (
          <div className="riverain-buttons">
            <div className="riverain-item" onClick={() => handlePageClick(esplanadeBarthesPage.link)}>
              <Link to={esplanadeBarthesPage.link}>Page précédente</Link>
            </div>
            <div className="riverain-item" onClick={() => handlePageClick(pontMarengoPage.link)}>
              <Link to={pontMarengoPage.link}>Page suivante</Link>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Genie;