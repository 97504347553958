// BannerAbout.js
import React from 'react';
import BannerImage from '../../src/assets/img/scroll.png';  

const BannerAbout = ({ children, title }) => {
    return (
      <div className="banner">
        <div className="banner-content">
          <img src={BannerImage} alt="Banner Title" className="banner-image" />
          <h2 className="banner-title">{title}</h2>

        </div>
        {children}
        <style jsx>{`
          .banner {
            background-color: #da8b2a;
            padding: 3rem;
            text-align: center;
            position: relative;
          }
  
          .banner-content {
            position: relative;
          }
  
          .banner-image {
            max-width: 100%;
            height: auto;
            margin-bottom: 20px;
            opacity: 87%;
          }
  
          .banner-title {
            position: absolute;
            top: 42%; 
            left: 50%; 
            transform: translate(-50%, -50%) skewY(-10deg);
            margin: 0;
            display: inline-block;
          }
        `}</style>
      </div>
    );
  };
  
  export default BannerAbout;