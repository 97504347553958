import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import BannerPage from '../../../components/BannerPage';
import Footer from '../../../components/Footer';
import '../../../App.css';
import ruesData from '../../../data/rues.json';

const Bert = () => {
  const navigate = useNavigate();
  
  const handlePageClick = (link) => {
    navigate(link);
  };

  // Filtrer le tableau ruesData pour récupérer uniquement l'élément correspondant à "Bert"
  const bastiatPage = ruesData.find((page) => page.name === "Bastiat");
  const boufflersPage = ruesData.find((page) => page.name === "Boufflers");

  return (
    <div>
      <BannerPage>
        <div>
          <h2>Bert</h2>
          <h3>Histoire et photos des rues, monuments et ponts du petit Bayonne</h3>
        </div>
      </BannerPage>
      <div className="riverain-container">
        <div className="image-container2">
          <img src="https://upload.wikimedia.org/wikipedia/commons/2/26/Paul_Bert_01.jpg" alt="Paul Bert" className="riverain-image" />
          <figcaption>Paul Bert</figcaption>
        </div>
        <div className='apropos-content'>
          <h4>Place Paul BERT</h4>
          <p className='histoire'>
          Anciens Noms : <strong> Place du collège – Place de la Ramade- en 1593 Place des Lisses</strong><br /> <br />
          1833/1886 – Médecin – Homme politique – Ministre instruction publique<br />   <br />
          Paul Bert, né le 19 octobre 1833 à Auxerre et mort le 11 novembre 1886 à Hanoï, est un médecin, physiologiste et homme politique français.<br /> <br />
          Élève de Claude Bernard, suppléant de Pierre Flourens au Muséum national d’histoire naturelle, il étudie la physiologie de la respiration (en altitude et en plongée) et s’intéresse à la greffe et à l’anesthésie.<br /> <br />
          Élu député radical à partir de 1872, il est ministre de l’Instruction publique et des Cultes de 1881 à 1882. Anticlérical, il est l’un des fondateurs de l’« école gratuite, laïque et obligatoire » loi du 09/08/1879 imposant la création d’au moins une École normale de garçons mais aussi de filles par département, pour la formation des « hussards noirs ».<br /> <br /> Il s’attacha spécialement à la scolarisation des enfants et des jeunes filles, et rédigea plusieurs ouvrages d’enseignement scientifique d’une grande valeur pédagogique, mais dans lesquels il exprimait – et par lesquels furent diffusées – ses idées concernant la supériorité de la race blanche. Soutien de la politique de colonisation, il publie plusieurs manuels scolaires jugés rétrospectivement comme étant racialistes. Ministre des Cultes, il se consacra à la lutte contre le cléricalisme.<br /> <br /> Il a été membre de plusieurs sociétés de libres penseurs dont la plupart se sont créées au début des années 1880. Il publia en 1880 l’ouvrage « La morale des Jésuites » puis en 1881 un manuel d’instruction civique de coloration nettement anticléricale, qui fut mis à l’Index par l’Église catholique. Paul Bert fut membre fondateur et vice-président jusqu’à sa mort de l’Union de propagande démocratique anticléricale.
          <br /> <br />
          En janvier 1886, il est nommé résident supérieur de l’Annam–Tonkin, en Indochine, où, où il meurt quelques mois plus tard des suites du choléra.
          </p>
        </div>
      </div>
      <div className="navigation-buttons">
        {/* Afficher le bouton seulement si l'élément correspondant existe */}
        {bastiatPage && boufflersPage && (
          <div className="riverain-buttons">
            <div className="riverain-item" onClick={() => handlePageClick(bastiatPage.link)}>
              <Link to={bastiatPage.link}>Page précédente</Link>
            </div>
            <div className="riverain-item" onClick={() => handlePageClick(boufflersPage.link)}>
              <Link to={boufflersPage.link}>Page suivante</Link>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};


export default Bert;