import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import BannerPage from '../../../components/BannerPage';
import Footer from '../../../components/Footer';
import '../../../App.css';
import ruesData from '../../../data/rues.json';

const Pannecau = () => {
  const navigate = useNavigate();
  
  const handlePageClick = (link) => {
    navigate(link);
  };

  // Filtrer le tableau ruesData pour récupérer uniquement l'élément correspondant à "Bert"
  const mousserollesPage = ruesData.find((page) => page.name === "Mousserolles – Ravignan");
  const pelletierPage = ruesData.find((page) => page.name === "Pelletier");

  return (
    <div>
      <BannerPage>
        <div>
          <h2>Pannecau</h2>
          <h3>Histoire et photos des rues, monuments et ponts du petit Bayonne</h3>
        </div>
      </BannerPage>
      <div className="riverain-container">
        <div className="image-container2">
          <img src="https://upload.wikimedia.org/wikipedia/commons/7/79/Bayonne-Rue_Port_Neuf-1900.jpg" alt="Rue Pannecau 1900" className="riverain-image" />
          <figcaption>Rue Pannecau 1900</figcaption>
        </div>
        <div className='apropos-content'>
          <h4>Rue PANNECAU</h4>
          <p className='histoire'>
          Ancien nom : <strong> rue Marat</strong><br /><br />
          Changement de numérotation en 1855<br />   <br />
          L’urbanisation du Bourg-neuf et de Pannecau, opère sur le même modèle d’une alternance de levées de terre et de canaux. Les rues Pannecau, des Tonneliers, des Cordeliers et de Galuperie, forment aussi des «esters» enjambés par des «pontics», passerelles en bois, à hauteur des berges de la Nive et de la rue des «Pontrique» qui porte bien son nom.<br /> <br />
          C’est un quartier séparé du quartier Bourg-neuf , on y trouve la fontaine Marrat.
          <br /><br />
          En 1597 la rue Pannecau est pavée.
          <br /><br />
          De Pane Cau (pain chaud) ou panayre choux (voleurs de choux dans le potager des Jacobins) ou Pane (Panar = voleur) Cau (choux ou sillon) = voleur de sillons.
          </p>
        </div>
      </div>
      <div className="navigation-buttons">
        {/* Afficher le bouton seulement si l'élément correspondant existe */}
        {mousserollesPage && pelletierPage && (
          <div className="riverain-buttons">
            <div className="riverain-item" onClick={() => handlePageClick(mousserollesPage.link)}>
              <Link to={mousserollesPage.link}>Page précédente</Link>
            </div>
            <div className="riverain-item" onClick={() => handlePageClick(pelletierPage.link)}>
              <Link to={pelletierPage.link}>Page suivante</Link>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};


export default Pannecau;