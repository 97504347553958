import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import BannerPage from '../../../components/BannerPage';
import Footer from '../../../components/Footer';
import '../../../App.css';
import ruesData from '../../../data/rues.json';

const Cordeliers = () => {
  const navigate = useNavigate();
  
  const handlePageClick = (link) => {
    navigate(link);
  };

  // Filtrer le tableau ruesData pour récupérer uniquement l'élément correspondant à "Bert"
  const charcutierePage = ruesData.find((page) => page.name === "Charcutière");
  const corsairesPage = ruesData.find((page) => page.name === "Corsaires");

  return (
    <div>
      <BannerPage>
        <div>
          <h2>Cordeliers</h2>
          <h3>Histoire et photos des rues, monuments et ponts du petit Bayonne</h3>
        </div>
      </BannerPage>
      <div className="riverain-container">
        <div className="image-container2">
          <img src="https://petitbayonne18852000.files.wordpress.com/2022/11/1973-rue-des-cordeliers.jpg" alt="Rue Cordeliers 1973" className="riverain-image" />
          <figcaption>Rue Cordeliers 1973</figcaption>
        </div>
        <div className='apropos-content'>
          <h4>Rue des Cordeliers</h4>
          <p className='histoire'>
          Anciens noms : <strong>rue de Memons – rue Saint Bénédict – rue Sainte Claire – en 1700 rue des Grouillots (grenouilles)</strong><br /> <br />
          Anciennes ruelles y débouchant :<strong>de l’Arsenal et Saint Bénédit</strong><br />   <br />
          En partie sur l’emplacement du Couvent des Frères Mineurs et Franciscains.<br /><br />
          Les Cordeliers, Mineurs ou Franciscains, apparaissent vers 1222-1228, pratiquement en même temps que les Jacobins. Ils s’installent probablement à la rue qui porte aujourd’hui leur nom, appelée alors «San-Bénédit». En 1242, Lévêque et le Chapitre leur cèdent les droits sur un oratoire situé près du «Clos des galées».
          <br /><br />
          L’année suivante, le roi donne «quarante marcs» à l’«opus fratum minorum Baione» et, en 1283, il leur cède les terrains attenant à leur oratoire d’une contenance de 28 brasses de côté «en bordure de la Nive. et à l’intérieur de l’enceinte», et 100 livres bordelaises afin d’y construire leur couvent. Terminé vers 1300, celui-ci brûle en 1333.
          <br /><br />
          Les Cordeliers abritent l’artillerie, les Jacobins et les Capucins, servent d’hôpitaux et les Clarisses de magasin. En 1721, la rue des Cordeliers est pavée. Puis, jusqu’en 1732, les prostituées sont gardées à l’hôpital Saint Léon, une maison située rue des Cordeliers servant à leur enfermement après cette date. Le couvent est démoli en 1833 pour construire des casernes en 1836. À cette date, toute trace du couvent a disparu, l’«Atlas des bâtiments militaires» faisant figurer à cette date les seuls nouveaux bâtiments de l’Arsenal.
          <br /><br />
          Les Cordeliers abritent l’artillerie, les Jacobins et les Capucins, servent d’hôpitaux et les Clarisses de magasin. En 1721, la rue des Cordeliers est pavée. Puis, jusqu’en 1732, les prostituées sont gardées à l’hôpital Saint Léon, une maison située rue des Cordeliers servant à leur enfermement après cette date.
          </p>
        </div>
      </div>
      <div className="navigation-buttons">
        {/* Afficher le bouton seulement si l'élément correspondant existe */}
        {charcutierePage && corsairesPage && (
          <div className="riverain-buttons">
            <div className="riverain-item" onClick={() => handlePageClick(charcutierePage.link)}>
              <Link to={charcutierePage.link}>Page précédente</Link>
            </div>
            <div className="riverain-item" onClick={() => handlePageClick(corsairesPage.link)}>
              <Link to={corsairesPage.link}>Page suivante</Link>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};



export default Cordeliers;