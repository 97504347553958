import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import BannerPage from '../../../components/BannerPage';
import Footer from '../../../components/Footer';
import '../../../App.css';
import ruesData from '../../../data/rues.json';

const Marsan = () => {
  const navigate = useNavigate();
  
  const handlePageClick = (link) => {
    navigate(link);
  };

  // Filtrer le tableau ruesData pour récupérer uniquement l'élément correspondant à "Bert"
  const marengoPage = ruesData.find((page) => page.name === "Marengo");
  const mousserollesPage = ruesData.find((page) => page.name === "Mousserolles – Ravignan");

  return (
    <div>
      <BannerPage>
        <div>
          <h2>Marsan</h2>
          <h3>Histoire et photos des rues, monuments et ponts du petit Bayonne</h3>
        </div>
      </BannerPage>
      <div className="riverain-container">
        <div className="image-container2">
          <img src="https://media.sudouest.fr/3064907/1200x-1/so-5b5717c566a4bd3f4cb04b7a-ph0.jpg?v=1532881705" alt="Rue de Marsan fête de Bayonne" className="riverain-image" />
          <figcaption>Rue de Marsan fête de Bayonne</figcaption>
        </div>
        <div className='apropos-content'>
          <h4>Rue MARSAN</h4>
          <p className='histoire'>
          Ancien noms : : <strong>18e S rue de Mimizan ou de Mormizan</strong><br /> <br />
          Vicomte de Marsan dans les landes ? ou Nom d’un bourgeois issu des landes ?<br />   <br />
          </p>
        </div>
      </div>
      <div className="navigation-buttons">
        {/* Afficher le bouton seulement si l'élément correspondant existe */}
        {marengoPage && mousserollesPage && (
          <div className="riverain-buttons">
            <div className="riverain-item" onClick={() => handlePageClick(marengoPage.link)}>
              <Link to={marengoPage.link}>Page précédente</Link>
            </div>
            <div className="riverain-item" onClick={() => handlePageClick(mousserollesPage.link)}>
              <Link to={mousserollesPage.link}>Page suivante</Link>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};


export default Marsan;