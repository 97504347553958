import { Outlet, Link } from "react-router-dom";
import React from "react";

const Layout = () => {
  return (
    <>
      <nav className="sidebar">
        <ul className="nav">
          <li>
            <Link to="/">Accueil</Link>
          </li>
          <li>
            <Link to="/a-propos">A propos</Link>
          </li>
          <li>
            <Link to="/riverains">Riverains</Link>
          </li>
          <li>
            <Link to="/histoire">Histoire</Link>
          </li>
          <li>
            <Link to="/aujourdhui">Aujourd'hui</Link>
          </li>
          <li>
            <Link to="/classement-alpha">Classement Alpha</Link>
          </li>
          <li>
            <Link to="/lexique">Lexique des métiers</Link>
          </li>
          <li>
            <Link to="https://forms.gle/6ED9LhPwh3iHvbHy8" target="_blank">Contact</Link>
          </li>
        </ul>
      </nav>

      <Outlet />
    </>
  );
};

export default Layout;