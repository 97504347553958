import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import BannerPage from '../../../components/BannerPage';
import Footer from '../../../components/Footer';
import '../../../App.css';
import monumentsData from '../../../data/monuments.json';

const Pmayou = () => {
  const navigate = useNavigate();
  
  const handlePageClick = (link) => {
    navigate(link);
  };

  // Filtrer le tableau ruesData pour récupérer uniquement l'élément correspondant à "Bert"
  const pontMarengoPage = monumentsData.find((page) => page.name === "Pont Marengo");
  const pontPannecauPage = monumentsData.find((page) => page.name === "Pont Pannecau");

  return (
    <div>
      <BannerPage>
        <div>
          <h2>Pont MAYOU</h2>
          <h3>Histoire et photos des rues, monuments et ponts du petit Bayonne</h3>
        </div>
      </BannerPage>
      <div className="riverain-container">
        <div className="image-container2">
          <img src="https://petitbayonne18852000.files.wordpress.com/2022/11/19es-le-port.jpg" alt="Port de Bayonne au 19e siècle" className="riverain-image" />
          <figcaption>Port de Bayonne au 19e siècle</figcaption>
        </div>
        <div className='apropos-content'>
          <h4>Pont MAYOU</h4>
          <p className='histoire'>
          Anciens noms : <strong>Pont Major – Pont Chégaray</strong><br /> <br />
          Napoléon Ier lors de son passage dans la cité, décide la reconstruction du pont en charpente reposant sur 2 culées en maçonnerie. Victime des crues de la Nive, il sera remplacé par un ouvrage en pierre en 1857.
          Il est élargi en 1914 pour le passage du tramway.<br />   <br />
          </p>
        </div>
      </div>
      <div className="navigation-buttons">
        {/* Afficher le bouton seulement si l'élément correspondant existe */}
        {pontMarengoPage && pontPannecauPage && (
          <div className="riverain-buttons">
            <div className="riverain-item" onClick={() => handlePageClick(pontMarengoPage.link)}>
              <Link to={pontMarengoPage.link}>Page précédente</Link>
            </div>
            <div className="riverain-item" onClick={() => handlePageClick(pontPannecauPage.link)}>
              <Link to={pontPannecauPage.link}>Page suivante</Link>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Pmayou;