import React from 'react';
import Footer from '../components/Footer';
import BannerPage from '../components/BannerPage';
import '../App.css'

const Home = () => {
  return (
    <div>
      <BannerPage>
      <div className="banner">
        <h1>Petit Bayonne, les rues et métiers d’antan</h1>
        <audio controls>
          <source src="https://www.lapurdi.net/IMG/mp3/13022023_claude_jammaerts.mp3" type="audio/mp3" />
          Votre navigateur ne prend pas en charge l'élément audio.
        </audio>
        <p className="subtitle">Par Claude Jammaerts</p>
      </div>
      </BannerPage>
      <div className="image-container">
        <img src="https://www.velobecane.com/img/cms/bayonne%20en%20velo%20electrique.jpeg" alt="quai jaureguiberry bayonne"/>
        <figcaption>Quai jaureguiberry bayonne</figcaption>
      </div>
      <Footer />
    </div>
  );
};


export default Home;