import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import BannerPage from '../../../components/BannerPage';
import Footer from '../../../components/Footer';
import '../../../App.css';
import ruesData from '../../../data/rues.json';

const Laffite = () => {
  const navigate = useNavigate();
  
  const handlePageClick = (link) => {
    navigate(link);
  };

  // Filtrer le tableau ruesData pour récupérer uniquement l'élément correspondant à "Bert"
  const galuperiePage = ruesData.find((page) => page.name === "Galuperie");
  const lescaPage = ruesData.find((page) => page.name === "Lesca");

  return (
    <div>
      <BannerPage>
        <div>
          <h2>Jacques Laffite</h2>
          <h3>Histoire et photos des rues, monuments et ponts du petit Bayonne</h3>
        </div>
      </BannerPage>
      <div className="riverain-container">
        <div className="image-container2">
          <img src="https://petitbayonne18852000.files.wordpress.com/2022/11/pharmacie-pradier-1906-1908-1.jpg" alt="Pharmacie Pradier 1906-1908" className="riverain-image" />
          <figcaption>Pharmacie Pradier 1906-1908</figcaption>
        </div>
        <div className='apropos-content'>
          <h4>Rue Jacques Laffitte</h4>
          <p className='histoire'>
          Anciens noms : passage Jacobins – rue Bouche Ben<br /> <br />
          1767-1844 –Homme politique – Cocréateur de la Banque de France<br />   <br />
          Jacques Laffitte né à Bayonne en 1767 au 3 rue Trinquet actuel (anciennement rue Maubec) et mort à Paris en 1844, est un homme politique, président du Conseil et ministre des finances de novembre 1830 à mars 1831; créateur et gouverneur de la Banque de France<br /><br />
          Parmi les tracés prévus, seule la rue Laffitte est partiellement réalisée. Jusqu’en 1732, les prostituées sont gardées à l’hôpital Saint-Léon, une maison située rue des Cordeliers servant à leur enfermement après cette date.
          <br /><br />
          Un premier bâtiment affecté à la Poste est construit en 1912 au n° 14 de la rue Laffitte devenu par après le Commissariat de Police.
          <br /><br />
          Franc-Maçon à la loge les 3 Jours.
          </p>
        </div>
        <div className='apropos-content'>
          <h4>Musée BONAT-HELLEU (rue Jacques Laffitte)</h4>
          <p className='histoire'>
          Musée des Beaux-Arts de Bayonne est un des principaux espaces culturels de la ville. Ses collections comprennent de riches œuvres (peintures, sculptures, arts graphiques) issues de legs et de donations intervenues depuis 1891. Les visiteurs peuvent notamment y admirer des chefs-d’œuvre (écoles allemande, anglaise, espagnole, flamande, hollandaise, française et italienne) des plus grands maîtres, tels que Rembrandt, Van Dyck, Delacroix, Murillo, Goya<br /> <br />
          </p>
        </div>
      </div>
      <div className="navigation-buttons">
        {/* Afficher le bouton seulement si l'élément correspondant existe */}
        {galuperiePage && lescaPage && (
          <div className="riverain-buttons">
            <div className="riverain-item" onClick={() => handlePageClick(galuperiePage.link)}>
              <Link to={galuperiePage.link}>Page précédente</Link>
            </div>
            <div className="riverain-item" onClick={() => handlePageClick(lescaPage.link)}>
              <Link to={lescaPage.link}>Page suivante</Link>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};


export default Laffite;