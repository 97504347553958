import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import BannerPage from '../../../components/BannerPage';
import Footer from '../../../components/Footer';
import '../../../App.css';
import ruesData from '../../../data/rues.json';

const Boufflers = () => {
  const navigate = useNavigate();
  
  const handlePageClick = (link) => {
    navigate(link);
  };

  // Filtrer le tableau ruesData pour récupérer uniquement l'élément correspondant à "Bert"
  const bertPage = ruesData.find((page) => page.name === "Bert");
  const bourgneufPage = ruesData.find((page) => page.name === "Bourgneuf");

  return (
    <div>
      <BannerPage>
        <div>
          <h2>Boufflers</h2>
          <h3>Histoire et photos des rues, monuments et ponts du petit Bayonne</h3>
        </div>
      </BannerPage>
      <div className="riverain-container">
        <div className="image-container2">
          <img src="https://www.hyacinthe-rigaud.com/media/djcatalog2/images/item/4/boufflers-jean-louis-francois-de_f.jpg" alt="Jean Louis François de Boufflers" className="riverain-image" />
          <figcaption>Jean Louis François de Boufflers</figcaption>
        </div>
        <div className='apropos-content'>
          <h4>Allées BOUFFLERS</h4>
          <p className='histoire'>
          Ancien nom : <strong>Fosse aux mâts</strong><br /><br />
          Louis-François, chevalier, puis marquis et enfin, duc de Boufflers (1644 – 1711), maréchal de France (il sauva l’armée française à Malpaquet) et pair de France en 1708. Il était en visite à Bayonne quand la ville achevait la réalisation de cette promenade à laquelle on donna, tout naturellement son nom.<br /><br />
          Lorsque le peintre du roi Louis XV, Joseph Vernet, représenta le port de Bayonne en 1764, les allées Boufflers constituait un lieu de promenade pour observer la fosse aux mâts ou le calfatage des navires. Sous le Second Empire, une rangée d’immeubles se développe le long des allées, entre les remparts de Mousserolles et la porte de France.<br /> <br />
          Les allées qui précèdent la courtine des Jacobins, derrière la «Fosse aux mâts» débutent par la plantation d’ormeaux en 1638. Elles sont poursuivies et confortées en 1671 et Vauban fait élargir leur terre-plein en utilisant les déblais des fossés qu’il creuse en élevant la courtine des Jacobins. En bordure des allées sont construites des «cayennes», appentis servant à entreposer le matériel des chantiers navals qui occupent les bords de l’Adour. Ainsi, le quartier prend le nom de «la Hutte».
          <br /><br />
          L’ingénieur Bérard dessine à nouveau et replante les allées en 1772. A la suite de ses nombreuses demandes, la ville récupère et urbanise les terrains à partir de 1857, comblant la «Fosse aux mâts» et faisant disparaître les allées.
          </p>
        </div>
      </div>
      <div className="navigation-buttons">
        {/* Afficher le bouton seulement si l'élément correspondant existe */}
        {bertPage && bourgneufPage && (
          <div className="riverain-buttons">
            <div className="riverain-item" onClick={() => handlePageClick(bertPage.link)}>
              <Link to={bertPage.link}>Page précédente</Link>
            </div>
            <div className="riverain-item" onClick={() => handlePageClick(bourgneufPage.link)}>
              <Link to={bourgneufPage.link}>Page suivante</Link>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};


export default Boufflers;