import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import BannerPage from '../../../components/BannerPage';
import Footer from '../../../components/Footer';
import '../../../App.css';
import ruesData from '../../../data/rues.json';

const Pelletier = () => {
  const navigate = useNavigate();
  
  const handlePageClick = (link) => {
    navigate(link);
  };

  // Filtrer le tableau ruesData pour récupérer uniquement l'élément correspondant à "Bert"
  const pannecauPage = ruesData.find((page) => page.name === "Pannecau");
  const pontriquePage = ruesData.find((page) => page.name === "Pontrique");

  return (
    <div>
      <BannerPage>
        <div>
          <h2>Pelletier</h2>
          <h3>Histoire et photos des rues, monuments et ponts du petit Bayonne</h3>
        </div>
      </BannerPage>
      <div className="riverain-container">
        <div className="image-container2">
          <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/54/Pierre_Joseph_Pelletier.jpg/1200px-Pierre_Joseph_Pelletier.jpg" alt="PELLETIER Pierre-Joseph" className="riverain-image" />
          <figcaption>PELLETIER Pierre-Joseph</figcaption>
        </div>
        <div className='apropos-content'>
          <h4>Rue PELLETIER Pierre-Joseph</h4>
          <p className='histoire'>
          Ancien nom : <strong>Rue de l’Arsenal</strong><br /> <br />
          Changement de numérotation en 1855<br /><br />
          Pierre Joseph Pelletier (né le 22 avril 1788 à Paris et mort le 19 juillet 1842 à Clichy-la-Garenne) est un pharmacien et chimiste français. Il fait des recherches importantes sur les alcaloïdes d’origine végétale et découvre avec Joseph Caventou la quinine et la strychnine.<br /> <br />
          Joseph Pelletier est le fils du pharmacien et chimiste Bertrand Pelletier (1761-1797) et le petit-fils de Bertrand Pelletier (1729-1784), Maître apothicaire à Bayonne.1761/1797 – Pharmacien et chimiste français.
          <br /><br />
          Longeait le haut mur de la Caserne de la Nive.
          </p>
        </div>
      </div>
      <div className="navigation-buttons">
        {/* Afficher le bouton seulement si l'élément correspondant existe */}
        {pannecauPage && pontriquePage && (
          <div className="riverain-buttons">
            <div className="riverain-item" onClick={() => handlePageClick(pannecauPage.link)}>
              <Link to={pannecauPage.link}>Page précédente</Link>
            </div>
            <div className="riverain-item" onClick={() => handlePageClick(pontriquePage.link)}>
              <Link to={pontriquePage.link}>Page suivante</Link>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};



export default Pelletier;