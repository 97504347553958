import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import BannerPage from '../../../components/BannerPage';
import Footer from '../../../components/Footer';
import '../../../App.css';
import monumentsData from '../../../data/monuments.json';

const Pmarengo = () => {
  const navigate = useNavigate();
  
  const handlePageClick = (link) => {
    navigate(link);
  };

  // Filtrer le tableau ruesData pour récupérer uniquement l'élément correspondant à "Bert"
  const pontGeniePage = monumentsData.find((page) => page.name === "Pont du Génie");
  const pontMayouPage = monumentsData.find((page) => page.name === "Pont Mayou");

  return (
    <div>
      <BannerPage>
        <div>
          <h2>Pont Marengo</h2>
          <h3>Histoire et photos des rues, monuments et ponts du petit Bayonne</h3>
        </div>
      </BannerPage>
      <div className="riverain-container">
        <div className="image-container2">
          <img src="https://petitbayonne18852000.files.wordpress.com/2022/11/pont-marengo-4.jpg" alt="Pont Marengo" className="riverain-image" />
          <figcaption>Pont Marengo</figcaption>
        </div>
        <div className='apropos-content'>
          <h4>Pont Marengo</h4>
          <p className='histoire'>
          Anciens noms : <strong>Pont Traversant – Pont Napoléon III</strong> <br /> <br />
          Son nom célèbre la victoire italienne de Napoléon Ier face aux autrichiens en 1800.<br />   <br />
          Une passerelle métallique suspendue, dite «en fil de fer» est projetée en 1836 et ouverte en 1839. Elle est à péage et elle dure jusqu’en 1854. En 1857, elle est remplacée par une autre passerelle en bois, dite «américaine», avant le pont maçonné construit en 1864.<br /> <br />
          </p>
        </div>
      </div>
      <div className="navigation-buttons">
        {/* Afficher le bouton seulement si l'élément correspondant existe */}
        {pontGeniePage && pontMayouPage && (
          <div className="riverain-buttons">
            <div className="riverain-item" onClick={() => handlePageClick(pontGeniePage.link)}>
              <Link to={pontGeniePage.link}>Page précédente</Link>
            </div>
            <div className="riverain-item" onClick={() => handlePageClick(pontMayouPage.link)}>
              <Link to={pontMayouPage.link}>Page suivante</Link>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Pmarengo;