import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import BannerPage from '../../../components/BannerPage';
import Footer from '../../../components/Footer';
import '../../../App.css';
import ruesData from '../../../data/rues.json';

const Pontrique = () => {
  const navigate = useNavigate();
  
  const handlePageClick = (link) => {
    navigate(link);
  };

  // Filtrer le tableau ruesData pour récupérer uniquement l'élément correspondant à "Bert"
  const pelletierPage = ruesData.find((page) => page.name === "Pelletier");
  const reduitPage = ruesData.find((page) => page.name === "Réduit");

  return (
    <div>
      <BannerPage>
        <div>
          <h2>Pontrique</h2>
          <h3>Histoire et photos des rues, monuments et ponts du petit Bayonne</h3>
        </div>
      </BannerPage>
      <div className="riverain-container">
        <div className="image-container2">
          <img src="https://cdt64.media.tourinsoft.eu/upload/Exposition-dans-le-cadre-de-la-foire-au-jambon-de-Bayonne.jpg" alt="Galerie des Corsaires - rue Pontrique" className="riverain-image" />
          <figcaption>Galerie des Corsaires - rue Pontrique</figcaption>
        </div>
        <div className='apropos-content'>
          <h4>Rue PONTRIQUE (ou Pontics)</h4>
          <p className='histoire'>
          Ancien nom : <strong>rue du Peuple</strong><br /> <br />
          Changement de numérotation en 1855<br />   <br />
          <strong>Pontic :</strong> Passerelles en bois pour enjamber les berges de la Nive et des canaux.<br /> <br />
          En 1623, on repave la rue Pontrique..
          </p>
        </div>
      </div>
      <div className="navigation-buttons">
        {/* Afficher le bouton seulement si l'élément correspondant existe */}
        {pelletierPage && reduitPage && (
          <div className="riverain-buttons">
            <div className="riverain-item" onClick={() => handlePageClick(pelletierPage.link)}>
              <Link to={pelletierPage.link}>Page précédente</Link>
            </div>
            <div className="riverain-item" onClick={() => handlePageClick(reduitPage.link)}>
              <Link to={reduitPage.link}>Page suivante</Link>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};


export default Pontrique;