import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import BannerPage from '../../../components/BannerPage';
import Footer from '../../../components/Footer';
import '../../../App.css';
import ruesData from '../../../data/rues.json';

const Galuperie = () => {
  const navigate = useNavigate();
  
  const handlePageClick = (link) => {
    navigate(link);
  };

  // Filtrer le tableau ruesData pour récupérer uniquement l'élément correspondant à "Bert"
  const coursicPage = ruesData.find((page) => page.name === "Coursic");
  const laffitePage = ruesData.find((page) => page.name === "Laffite et Musée BONAT-HELLEU");

  return (
    <div>
      <BannerPage>
        <div>
          <h2>Galuperie</h2>
          <h3>Histoire et photos des rues, monuments et ponts du petit Bayonne</h3>
        </div>
      </BannerPage>
      <div className="riverain-container">
        <div className="image-container2">
          <img src="https://4.bp.blogspot.com/--pqxbdfpINA/VREmBuMXu1I/AAAAAAAAFC8/DIwcHcdqXq4/s1600/galuperie1.jpg" alt="Quai Galuperie" className="riverain-image" />
          <figcaption>Frederic Bastiat</figcaption>
        </div>
        <div className='apropos-content'>
          <h4>Quai GALUPERIE</h4>
          <p className='histoire'>
          Ancien nom : <strong>quai de l’Arsenal</strong><br /> <br />
          Le quai Galuperie tire son nom des galupes qui étaient régulièrement amarrées à cet endroit. <br />   <br />
          La galupe était une embarcation fluviale à fond plat longues d’une quinzaine de mètres et partiellement pontée, se maniant à l’aviron, le halage ou parfois avec une voile.<br /> <br />
          Naissance en 1711 de Léon du Tillot marquis de Felino.
          </p>
        </div>
      </div>
      <div className="navigation-buttons">
        {/* Afficher le bouton seulement si l'élément correspondant existe */}
        {coursicPage && laffitePage && (
          <div className="riverain-buttons">
            <div className="riverain-item" onClick={() => handlePageClick(coursicPage.link)}>
              <Link to={coursicPage.link}>Page précédente</Link>
            </div>
            <div className="riverain-item" onClick={() => handlePageClick(laffitePage.link)}>
              <Link to={laffitePage.link}>Page suivante</Link>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};


export default Galuperie;