import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import BannerPage from '../../../components/BannerPage';
import Footer from '../../../components/Footer';
import '../../../App.css';
import monumentsData from '../../../data/monuments.json';
import ruesData from '../../../data/rues.json';

const Square = () => {
  const navigate = useNavigate();
  
  const handlePageClick = (link) => {
    navigate(link);
  };

  // Filtrer le tableau ruesData pour récupérer uniquement l'élément correspondant à "Bert"
  const pontPannecauPage = monumentsData.find((page) => page.name === "Pont Pannecau");
  const arsenalPage = ruesData.find((page) => page.name === "Arsenal");

  return (
    <div>
      <BannerPage>
        <div>
          <h2>Square Gabriel Léo Pouzacq</h2>
          <h3>Histoire et photos des rues, monuments et ponts du petit Bayonne</h3>
        </div>
      </BannerPage>
      <div className="riverain-container">
        <div className="image-container2">
          <img src="https://lh3.googleusercontent.com/p/AF1QipOl3P47SHRzwhDuGlVWs333Z7kkBhr-udKFkpzd=s1360-w1360-h1020" alt="Square Gabriel Léo Pouzacq" className="riverain-image" />
          <figcaption>Square Gabriel Léo Pouzacq</figcaption>
        </div>
        <div className='apropos-content'>
          <h4>Square Gabriel Léo Pouzacq</h4>
          <p className='histoire'>
          Ancien nom :<strong>rue de la République</strong> <br /> <br />
          Léo Pouzac fut maire de Bayonne de 1888 à 1908 et Vice Président du Conseil Général de 1892 à 1909<br />   <br />
          Le sculpteur est Maxime Réal Del Sarte. Jeanne d’Arc est magnifiquement représentée.<br /> <br />
          </p>
        </div>
      </div>
      <div className="navigation-buttons">
        {/* Afficher le bouton seulement si l'élément correspondant existe */}
        {pontPannecauPage && arsenalPage && (
          <div className="riverain-buttons">
            <div className="riverain-item" onClick={() => handlePageClick(pontPannecauPage.link)}>
              <Link to={pontPannecauPage.link}>Page précédente</Link>
            </div>
            <div className="riverain-item" onClick={() => handlePageClick(arsenalPage.link)}>
              <Link to={arsenalPage.link}>Page suivante</Link>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Square;