import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import BannerPage from '../../../components/BannerPage';
import Footer from '../../../components/Footer';
import '../../../App.css';
import ruesData from '../../../data/rues.json';

const Mousserolles = () => {
  const navigate = useNavigate();
  
  const handlePageClick = (link) => {
    navigate(link);
  };

  // Filtrer le tableau ruesData pour récupérer uniquement l'élément correspondant à "Bert"
  const marsanPage = ruesData.find((page) => page.name === "Marsan");
  const pannecauPage = ruesData.find((page) => page.name === "Pannecau");

  return (
    <div>
      <BannerPage>
        <div>
          <h2>Mousseroles – Ravignan</h2>
          <h3>Histoire et photos des rues, monuments et ponts du petit Bayonne</h3>
        </div>
      </BannerPage>
      <div className="riverain-container">
        <div className="image-container2">
          <img src="https://i.ebayimg.com/images/g/BSwAAOSwiyhcS12U/s-l1200.webp" alt="Porte de Mousserolles" className="riverain-image" />
          <figcaption>PORTES de MOUSSEROLLE</figcaption>
        </div>
        <div className='apropos-content'>
          <h4>PORTES de MOUSSEROLLE</h4>
          <p className='histoire'>
          La porte de Mousserolles de la redoute Est de l’Adour est conservée pratiquement en l’état, avec une casemate aux poudres et un corps de garde. Elle conserve une partie du mécanisme de l’ancien pont-levis.<br /> <br />
          Elle garde le souvenir de la reine douairière d’Espagne Marie-Anne de Neubourg, réfugiée à Bayonne depuis 1706, qui y fait ses adieux solennels au Conseil de ville en 1738 après avoir été autorisée à rentrer dans son pays d’origine. Aux alentours, dans les anciennes douves, une promenade arborée et des aires de jeux pour les enfants ont été aménagées<br />   <br />
          Pavillon X siège de l’Amicale du Petit Bayonne, pavillon Y Léo Lagrange et les 2 casemates Baïona Banda et l’A.S.B.<br /> <br />
          </p>
        </div>
      </div>
      <div className="riverain-container">
        <div className="image-container2">
          <img src="https://i.ebayimg.com/images/g/bSMAAOSwXeJYNVa8/s-l1200.webp" alt="Gustave-François RAVIGNAN" className="riverain-image" />
          <figcaption>Gustave-François RAVIGNAN</figcaption>
        </div>
        <div className='apropos-content'>
          <h4>Rue Gustave-François RAVIGNAN</h4>
          <p className='histoire'>
          Gustave-François-Xavier de La Croix de Ravignan, né le 1er décembre 1795 à Bayonne, et décédé le 26 février 1858 à Paris, était un prêtre jésuite français, directeur spirituel, écrivain et prédicateur de renom. De 1837 à 1846 il donna les Conférences de Carême à Notre-Dame de Paris..<br /> <br />
          </p>
        </div>
      </div>
      <div className="navigation-buttons">
        {/* Afficher le bouton seulement si l'élément correspondant existe */}
        {marsanPage && pannecauPage && (
          <div className="riverain-buttons">
            <div className="riverain-item" onClick={() => handlePageClick(marsanPage.link)}>
              <Link to={marsanPage.link}>Page précédente</Link>
            </div>
            <div className="riverain-item" onClick={() => handlePageClick(pannecauPage.link)}>
              <Link to={pannecauPage.link}>Page suivante</Link>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};


export default Mousserolles;