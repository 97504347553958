// APropos.js
import React from 'react';
import BannerAbout from '../components/BannerAbout';
import ImageGauche from '../assets/img/bayonne.jpg';
import ImageRonde from '../assets/img/claude.jpg';
import '../App.css'; 
import Footer from '../components/Footer';

const APropos = () => {
  return (
    <div>
      <BannerAbout title="A propos">
      </BannerAbout>
      <div className="apropos-container">
        <img src={ImageGauche} alt="Bayonne" className="image-gauche" />
        <div className="apropos-content">
          <img src={ImageRonde} alt="Claude Jammaerts" className="image-ronde" />
          <div className="text-container">
            <p className="intro">
              Bonjour à tous
            </p>
            <p>Je suis né un 24 décembre à Bruxelles, j’ai passé toute mon enfance en Afrique, puis j’ai bourlingué un peu en Europe.</p>
            <p>J’ai débarqué tout à fait par hasard en 1966 à Bayonne. Y ait tenu commerce à la rue des nobles et habité au Petit Bayonne et y habite toujours.</p>
            <p>Je suis tombé amoureux de Bayonne au premier regard et en pamoison au Petit-Bayonne.</p>
            <p>Bayonne est, pour moi, une maitresse coquine qui dévoile ses secrets peu à peu ou alors c’est mon regard qui, au fil des ans, devient plus intime.</p>
            <p>Le Petit Bayonne quartier entre Nive tumultueuse, caractérielle et l’Adour forte de sa puissance majestueuse se rejoignent au pied de l’Échauguette.</p>
            <p>C’est là que bat l’âme profonde de Bayonne, c’est là que s’est formé le caractère, le parler, les expressions spécifiques Gascogne.</p>
            <p>De l’implantation des différents Couvents vers 1200 à ce jour, la venue des boulanger et des tonneliers, du trinquet et des églises, des troquets et gargotes, des maisons closes (pas pour tous) et des restaurants de prestiges, que de changements, que d’histoires.</p>
            <p>Vous trouverez ci-après la liste par rue et par année des riverains qui avaient un métier déclarés lors des listes de recensement. Y est joint un lexique de tous ces anciens métiers.</p>
            <p>Vous trouverez aussi l’évolution et l’explication des noms des rue ainsi que des photos anciennes que j’ai glanées par ci par là.</p>
            <p>Il est évident que cette recherche historique n’est pas close et que beaucoup d’éléments manquent.</p>
            <p>Je compte sur vous pour l’enrichir de vos souvenirs, de vos anecdotes, de vos photos.</p>
            <p>Bonne lecture</p>
            <p className="signature">
              Claude Jammaerts
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default APropos;
