import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import BannerPage from '../../../components/BannerPage';
import Footer from '../../../components/Footer';
import '../../../App.css';
import ruesData from '../../../data/rues.json';

const Marengo = () => {
  const navigate = useNavigate();
  
  const handlePageClick = (link) => {
    navigate(link);
  };

  // Filtrer le tableau ruesData pour récupérer uniquement l'élément correspondant à "Bert"
  const lissesPage = ruesData.find((page) => page.name === "Lisses");
  const marsanPage = ruesData.find((page) => page.name === "Marsan");

  return (
    <div>
      <BannerPage>
        <div>
          <h2>Marengo</h2>
          <h3>Histoire et photos des rues, monuments et ponts du petit Bayonne</h3>
        </div>
      </BannerPage>
      <div className="riverain-container">
        <div className="image-container2">
          <img src="https://1.bp.blogspot.com/-uqE_DzJCch8/U-CECXtDN-I/AAAAAAAACEo/8Bsm3uwKhlg/s1600/marengo2.jpg" alt="Pont Marengo" className="riverain-image" />
          <figcaption>Pont Marengo</figcaption>
        </div>
        <div className='apropos-content'>
          <h4>Rue MARENGO</h4>
          <p className='histoire'>
          Ancien nom: <strong>rue Traversante – rue du Pont traversant (1857)</strong><br /><br />
          En 1800, la rue Marengo est percée à travers le couvent des Visitandines en prolongement de la rue de l’Hôpital. Subsistent les piliers du cloître et la façade d’un bâtiment qui, sous réserve de vérification, pourrait dater de la Renaissance au 20-22 rue Marengo. Au 19ème siècle, une partie du cloître est occupée par les «Bains de la Visitation».<br />   <br />
          Au 17ème siècle, existe rue Marengo une étuve publique, tenue par un barbier.<br /> <br />
          Un établissement de bains chauds occupe le couvent des Augustins en 1792 et au 19ème siècle, les <strong>«Bains de la Visitation»</strong>, occupent une partie de l’ancien couvent au 26 de la rue Marengo.
          <br /><br />
          (PT = Pont traversant)
          </p>
        </div>
      </div>
      <div className="navigation-buttons">
        {/* Afficher le bouton seulement si l'élément correspondant existe */}
        {lissesPage && marsanPage && (
          <div className="riverain-buttons">
            <div className="riverain-item" onClick={() => handlePageClick(lissesPage.link)}>
              <Link to={lissesPage.link}>Page précédente</Link>
            </div>
            <div className="riverain-item" onClick={() => handlePageClick(marsanPage.link)}>
              <Link to={marsanPage.link}>Page suivante</Link>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};


export default Marengo;