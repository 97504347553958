import React from 'react';
import { Link, } from 'react-router-dom';
import BannerPage from '../components/BannerPage';
import '../App.css';
import Footer from '../components/Footer';
import ruesData from '../data/rues.json';
import monumentsData from '../data/monuments.json';
import { useNavigate } from 'react-router-dom';



  const PageList = () => {
    const navigate = useNavigate(); 

    const handlePageClick = (link) => {
      navigate(link); 
    };
  return (
    <div>
      <BannerPage>
        <div>
          <h2>Un peu d'histoire</h2>
          <h3>Histoire et photos des rues, monuments et ponts du petit Bayonne</h3>
        </div>
      </BannerPage>
      <div>
          <div className="riverain-container">
          <h2>Rues</h2>
          <h3>Cliquez sur les liens pour en savoir plus</h3>
        <div className="image-container2">
          <img src="https://1.bp.blogspot.com/-DMbIOVh7awY/VcH6GV6Uy7I/AAAAAAAAGUw/bdd6K3GFpmU/s1600/port%2Bcastets02.jpg" alt="Pont Mayou" className="riverain-image" />
          <figcaption>Pont Mayou - Rue Port de Castets</figcaption>
        </div>
        <div className="riverain-buttons">
          {ruesData.map((page, index) => (
            <div key={index} className="riverain-item" onClick={() => handlePageClick(page.link)}>
              <Link to={page.link}>{page.name}</Link>
            </div>
          ))}
        </div>
      </div>
      <div>
          <div className="riverain-container">
          <h2>Monuments et Ponts</h2>
          <h3>Cliquez sur les liens pour en savoir plus</h3>
        <div className="image-container2">
          <img src="https://p.cartorum.fr/recto/maxi/000/376/366-bayonne-bayonne-monument-lavigerie-theatre.jpg" alt="Monument Lavigerie" className="riverain-image" />
          <figcaption>Monument Lavigerie</figcaption>
        </div>
      </div>
        <div className="riverain-buttons">
          {monumentsData.map((page, index) => (
            <div key={index} className="riverain-item" onClick={() => handlePageClick(page.link)}>
              <Link to={page.link}>{page.name}</Link>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
    </div>
  );
};

export default PageList;

