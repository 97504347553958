import React from 'react';
import BannerPage from '../components/BannerPage';
import '../App.css'; 
import Footer from '../components/Footer';
import { saveAs } from 'file-saver';
import riverainsData from '../data/riverains.json';

const Riverains = () => {
  const handleRiverainClick = (pdf) => {
    saveAs(pdf);
  };

  return (
    <div>
      <BannerPage>
        <div>
          <h2>Liste des riverains par rues</h2>
        </div>
      </BannerPage>
      <div className="riverain-container">
      <h3>Pdf à télécharger en cliquant sur le nom des rues.</h3>
        <div className="image-container2">
          <img src="https://upload.wikimedia.org/wikipedia/commons/c/c5/Bayonne-Quai_des_Halles-1900.jpg" alt="Bayonne 1900" className="riverain-image" />
          <figcaption>Le Quai des Halles - Bayonne 1900</figcaption>
        </div>
        <div className="riverain-buttons">
          {riverainsData.map((riverain, index) => (
            <div key={index} className="riverain-item" onClick={() => handleRiverainClick(require(`../pdf/${riverain.pdf}`))}>
              {riverain.name}
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Riverains;







