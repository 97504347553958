import React from 'react';


const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-columns">
        <div className="footer-column">
          <h3>Le livre</h3>
          <ul className="footer-links">
            <li><a href="https://www.librairie-darrieumerlou.fr/?view=product&lang=it_IT&product_id=34047">Librairie Darrieumerlou</a></li>
          </ul>
        </div>
        <div className="footer-column">
          <h3>Articles</h3>
          <ul className="footer-links">
          <li><a href="https://www.sudouest.fr/redaction/insolite/pays-basque-piqueuse-de-bottines-charron-ou-limonadier-le-petit-bayonne-raconte-a-travers-ses-metiers-disparus-14059697.php#:~:text=Vendeur%20de%20d%C3%A9corations%20et%20de,avoir%20exerc%C3%A9%20mille%20activit%C3%A9s%20diff%C3%A9rentes.">Sud Ouest</a></li>
          <li><a href="https://www.baskulture.com/article/vous-saurez-tout-sur-le-petit-bayonne-grce-claude-jammaerts-5567">Baskulture</a></li>
          </ul>
        </div>
        <div className="footer-column">
          <h3>Site</h3>
          <ul className="footer-links">
            <li><a href="https://www.bayonne.fr/">Ville de Bayonne</a></li>

          </ul>
        </div>
        
      </div>
      <p>Copyright © {new Date().getFullYear()} Claude Jammaerts</p>
    </footer>
  );
}

export default Footer;
