import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import BannerPage from '../../../components/BannerPage';
import Footer from '../../../components/Footer';
import '../../../App.css';
import ruesData from '../../../data/rues.json';

const Lisses = () => {
  const navigate = useNavigate();
  
  const handlePageClick = (link) => {
    navigate(link);
  };

  // Filtrer le tableau ruesData pour récupérer uniquement l'élément correspondant à "Bert"
  const lescaPage = ruesData.find((page) => page.name === "Lesca");
  const marengoPage = ruesData.find((page) => page.name === "Marengo");

  return (
    <div>
      <BannerPage>
        <div>
          <h2>Lisses</h2>
          <h3>Histoire et photos des rues, monuments et ponts du petit Bayonne</h3>
        </div>
      </BannerPage>
      <div className="riverain-container">
        <div className="image-container2">
          <img src="https://petitbayonne18852000.files.wordpress.com/2022/11/1908-mariage-treille.jpg?w=1024" alt="Claude Frederic Bastiat" className="riverain-image" />
          <figcaption>1908- mariage-treille</figcaption>
        </div>
        <div className='apropos-content'>
          <h4>Rue des LISSES</h4>
          <p className='histoire'>
          Anciens noms : <strong>rue Lepelletier (sous la révolution)</strong><br /> <br />
          Changement de numérotation en 1855<br />   <br />
          Barrières ou palissades protégeant les pieds des remparts ou <strong>RAMADE.</strong><br /> <br />
          Lieux de liesse danses et chants – 1er salle de spectacle en la maison d’André Dubosc seigneur d’Arcandau.
          </p>
        </div>
      </div>
      <div className="navigation-buttons">
        {/* Afficher le bouton seulement si l'élément correspondant existe */}
        {lescaPage && marengoPage && (
          <div className="riverain-buttons">
            <div className="riverain-item" onClick={() => handlePageClick(lescaPage.link)}>
              <Link to={lescaPage.link}>Page précédente</Link>
            </div>
            <div className="riverain-item" onClick={() => handlePageClick(marengoPage.link)}>
              <Link to={marengoPage.link}>Page suivante</Link>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};


export default Lisses;