import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import BannerPage from '../../../components/BannerPage';
import Footer from '../../../components/Footer';
import '../../../App.css';
import ruesData from '../../../data/rues.json';

const Bastiat = () => {
    const navigate = useNavigate();
  
    const handlePageClick = (link) => {
      navigate(link);
    };
  
    // Filtrer le tableau ruesData pour récupérer uniquement l'élément correspondant à "Bert"
    const arsenalPage = ruesData.find((page) => page.name === "Arsenal");
    const bertPage = ruesData.find((page) => page.name === "Bert");
  return (
    <div>
      <BannerPage>
        <div>
          <h2>Bastiat</h2>
          <h3>Histoire et photos des rues, monuments et ponts du petit Bayonne</h3>
        </div>
      </BannerPage>
      <div className="riverain-container">
        <div className="image-container2">
          <img src="https://i0.wp.com/leblogdenathaliemp.com/wp-content/uploads/2015/09/bastiat-1.png?fit=713%2C404&ssl=1" alt="Claude Frederic Bastiat" className="riverain-image" />
          <figcaption>Frederic Bastiat</figcaption>
        </div>
        <div className='apropos-content'>
          <h4>Rue Claude Frédéric BASTIAT</h4>
          <p className='histoire'>
          1801-1850 – Économiste et Homme politique<br /> <br />
          Né le 29/06/1801 à Bayonne au 7 de la rue actuelle Victor Hugo et mort en 1850 à Rome, est un économiste et homme politique libéral français. Son livre le plus connu : La Loi. Son plus fervent disciple à travers le temps fut Ronald Reagan, Président des Etats-Unis<br />   <br />
          Franc-maçon à la loge Bayonnaise La Zélée le 1819<br /> <br />
          La rue Bastiat suit les anciennes murailles de Jacobins
          </p>
        </div>
      </div>
      <div className="navigation-buttons">
        {/* Afficher le bouton seulement si l'élément correspondant existe */}
        {arsenalPage && bertPage && (
          <div className="riverain-buttons">
            <div className="riverain-item" onClick={() => handlePageClick(arsenalPage.link)}>
              <Link to={arsenalPage.link}>Page précédente</Link>
            </div>
            <div className="riverain-item" onClick={() => handlePageClick(bertPage.link)}>
              <Link to={bertPage.link}>Page suivante</Link>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Bastiat;
