import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import BannerPage from '../../../components/BannerPage';
import Footer from '../../../components/Footer';
import '../../../App.css';
import ruesData from '../../../data/rues.json';

const Chaho = () => {
  const navigate = useNavigate();
  
  const handlePageClick = (link) => {
    navigate(link);
  };

  // Filtrer le tableau ruesData pour récupérer uniquement l'élément correspondant à "Bert"
  const bourgneufPage = ruesData.find((page) => page.name === "Bourgneuf");
  const charcutierePage = ruesData.find((page) => page.name === "Charcutière");

  return (
    <div>
      <BannerPage>
        <div>
          <h2>Chaho</h2>
          <h3>Histoire et photos des rues, monuments et ponts du petit Bayonne</h3>
        </div>
      </BannerPage>
      <div className="riverain-container">
        <div className="image-container2">
          <img src="https://www.bilketa.eus/in/rest/public/Attachment/attach_cmsUpload_04c31c0d-740d-4ecc-bf26-94a71d328ac2" alt="Augustin Chaho" className="riverain-image" />
          <figcaption>Augustin Chaho</figcaption>
        </div>
        <div className='apropos-content'>
          <h4>Quai Agosti Xaho CHAHO</h4>
          <p className='histoire'>
          Ancien nom : <strong>quai de Merrons – Quai des Corsaires (1857)</strong><br /> <br />
          1811/1858 – Ecrivain – Homme politique<br />   <br />
          Augustin Chaho ou Agosti Xaho, né le 10 octobre 1811 à Tardets et mort le 22 octobre 1858 à Bayonne, est un écrivain, périodiste, indianiste, philologue et homme politique de langue basque et française. Conseiller Municipal à Bayonne<br /> <br />
          Franc-maçon en 1845 en la loge La Parfaite Réunion
          </p>
        </div>
      </div>
      <div className="navigation-buttons">
        {/* Afficher le bouton seulement si l'élément correspondant existe */}
        {bourgneufPage && charcutierePage && (
          <div className="riverain-buttons">
            <div className="riverain-item" onClick={() => handlePageClick(bourgneufPage.link)}>
              <Link to={bourgneufPage.link}>Page précédente</Link>
            </div>
            <div className="riverain-item" onClick={() => handlePageClick(charcutierePage.link)}>
              <Link to={charcutierePage.link}>Page suivante</Link>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};



export default Chaho;