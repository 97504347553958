import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import BannerPage from '../../../components/BannerPage';
import Footer from '../../../components/Footer';
import '../../../App.css';
import ruesData from '../../../data/rues.json';
import monumentsData from '../../../data/monuments.json';

const Neuf = () => {
  const navigate = useNavigate();
  
  const handlePageClick = (link) => {
    navigate(link);
  };

  // Filtrer le tableau ruesData pour récupérer uniquement l'élément correspondant à "Bert"
  const visitandinesPage = ruesData.find((page) => page.name === "Visitandines");
  const saintAndrePage = monumentsData.find((page) => page.name === "Eglise Saint André");
  
  return (
    <div>
      <BannerPage>
        <div>
          <h2>Château Neuf</h2>
          <h3>Histoire et photos des rues, monuments et ponts du petit Bayonne</h3>
        </div>
      </BannerPage>
      <div className="riverain-container">
        <div className="image-container2">
          <img src="https://petitbayonne18852000.files.wordpress.com/2022/11/chateau-neuf-apres-lincendie-de-1793.jpg" alt="Château-Neuf après l’incendie de 1793" className="riverain-image" />
          <figcaption>Château-Neuf après l’incendie de 1793</figcaption>
        </div>
        <div className='apropos-content'>
          <h4>Château Neuf</h4>
          <p className='histoire'>
          Ancien nom : <strong>Château Mocoron</strong><br /> <br />
          Sur les hauteurs de Mocoron, cet ensemble fortifié porte aujourd’hui la marque du maréchal de Vauban, qui l’adapte aux progrès militaires du XVIIème siècle et en fait le logement de la garnison chargée de défendre la route de l’Est (Urcuit, Urt, Guiche etc.).<br />   <br />
          Son origine est cependant beaucoup plus ancienne et remonte au XVème siècle et à la prise de la ville par les Français. Ce « Château-Neuf » (par opposition au « Château-Vieux » des vicomtes du Labourd situé un peu plus loin) est commencé sous Charles VII.<br /> <br />
          Son successeur Charles VIII en fait une puissante forteresse dotée de tours défensives et de courtines percées d’embrasures. L’édifice est aujourd’hui occupé par l’IUT de Bayonne.
          </p>
        </div>
      </div>
      <div className="navigation-buttons">
        {/* Afficher le bouton seulement si l'élément correspondant existe */}
        {visitandinesPage && saintAndrePage && (
          <div className="riverain-buttons">
            <div className="riverain-item" onClick={() => handlePageClick(visitandinesPage.link)}>
              <Link to={visitandinesPage.link}>Page précédente</Link>
            </div>
            <div className="riverain-item" onClick={() => handlePageClick(saintAndrePage.link)}>
              <Link to={saintAndrePage.link}>Page suivante</Link>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};


export default Neuf;