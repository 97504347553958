import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import BannerPage from '../../../components/BannerPage';
import Footer from '../../../components/Footer';
import '../../../App.css';
import monumentsData from '../../../data/monuments.json';

const SaintAndre = () => {
  const navigate = useNavigate();
  
  const handlePageClick = (link) => {
    navigate(link);
  };

  // Filtrer le tableau ruesData pour récupérer uniquement l'élément correspondant à "Bert"
  const neufPage = monumentsData.find((page) => page.name === "Château Neuf");
  const esplanadeBathesPage = monumentsData.find((page) => page.name === "Esplanade Roland Barthe");

  return (
    <div>
      <BannerPage>
        <div>
          <h2>Eglise Saint André</h2>
          <h3>Histoire et photos des rues, monuments et ponts du petit Bayonne</h3>
        </div>
      </BannerPage>
      <div className="riverain-container">
        <div className="image-container2">
          <img src="https://petitbayonne18852000.files.wordpress.com/2022/11/eglise-saint-andre-1-1.jpg?w=768" alt="Eglise Saint André" className="riverain-image" />
          <figcaption>Eglise Saint André</figcaption>
        </div>
        <div className='apropos-content'>
          <h4>Église SAINT ANDRÉ</h4>
          <p className='histoire'>
          Construction de 1856 à 1869.<br /> <br />
          Construit à l’emplacement d’une église médiévale grâce à un legs d’un paroissien, Mr Lormand, ce vaste édifice néo-gothique est l’œuvre des architectes Hippolyte Guichenné et Hippolyte Durand. Son édification commence en 1856 et se termine en 1869. Sa façade quelque peu massive s’inspire librement de la cathédrale Notre-Dame de Paris.<br />   <br />
          L’intérieur, très lumineux, est éclairé par trois rosaces inspirées du gothique rayonnant et par de grandes baies ogivales. Ne pas manquer de contempler l’orgue impérial « Wenner & Götty » datant de 1862 (35 jeux), classé monument historique depuis 2002.<br /> <br />
          Les clochers sont démontés , leur poids étant trop important sur le sol marécageux.
          </p>
        </div>
      </div>
      <div className="navigation-buttons">
        {/* Afficher le bouton seulement si l'élément correspondant existe */}
        {neufPage && esplanadeBathesPage && (
          <div className="riverain-buttons">
            <div className="riverain-item" onClick={() => handlePageClick(neufPage.link)}>
              <Link to={neufPage.link}>Page précédente</Link>
            </div>
            <div className="riverain-item" onClick={() => handlePageClick(esplanadeBathesPage.link)}>
              <Link to={esplanadeBathesPage.link}>Page suivante</Link>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default SaintAndre;