import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import BannerPage from '../../../components/BannerPage';
import Footer from '../../../components/Footer';
import '../../../App.css';
import ruesData from '../../../data/rues.json';

const Coursic = () => {
  const navigate = useNavigate();
  
  const handlePageClick = (link) => {
    navigate(link);
  };

  // Filtrer le tableau ruesData pour récupérer uniquement l'élément correspondant à "Bert"
  const corsairesPage = ruesData.find((page) => page.name === "Corsaires");
  const galuperiePage = ruesData.find((page) => page.name === "Galuperie");

  return (
    <div>
      <BannerPage>
        <div>
          <h2>Coursic</h2>
          <h3>Histoire et photos des rues, monuments et ponts du petit Bayonne</h3>
        </div>
      </BannerPage>
      <div className="riverain-container">
        <div className="image-container2">
          <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/69/Joannis_de_Suhigaraychipy_dit_Coursic.jpg/1200px-Joannis_de_Suhigaraychipy_dit_Coursic.jpg" alt="Claude Frederic Bastiat" className="riverain-image" />
          <figcaption>Corsaire Johannis de Suhigaraychipy</figcaption>
        </div>
        <div className='apropos-content'>
          <h4>Rue de COURSIC (1985)</h4>
          <p className='histoire'>
          Ancien nom : <strong>rue Galuperie (1946)</strong><br /> <br />
          Corsaire Johannis de Suhigaraychipy, dit «Croisic» ou «Coursic», dont la maison natale serait celle des arceaux qui avancent sur la rue.<br />   <br />
          Les corsaires ramènent au port 40 navires adverses en 1690, 90 en 1691, 52 en 1692… Le plus célèbre d’entre-deux est alors Johannis de Suhigaraychipy, dit «Croisic» ou «Coursic».<br /> <br />
          </p>
        </div>
      </div>
      <div className="navigation-buttons">
        {/* Afficher le bouton seulement si l'élément correspondant existe */}
        {corsairesPage && galuperiePage && (
          <div className="riverain-buttons">
            <div className="riverain-item" onClick={() => handlePageClick(corsairesPage.link)}>
              <Link to={corsairesPage.link}>Page précédente</Link>
            </div>
            <div className="riverain-item" onClick={() => handlePageClick(galuperiePage.link)}>
              <Link to={galuperiePage.link}>Page suivante</Link>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};


export default Coursic;