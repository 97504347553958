import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import BannerPage from '../../../components/BannerPage';
import Footer from '../../../components/Footer';
import '../../../App.css';
import ruesData from '../../../data/rues.json';

const Reduit = () => {
  const navigate = useNavigate();
  
  const handlePageClick = (link) => {
    navigate(link);
  };

  // Filtrer le tableau ruesData pour récupérer uniquement l'élément correspondant à "Bert"
  const pontriquePage = ruesData.find((page) => page.name === "Pontrique");
  const saubiolePage = ruesData.find((page) => page.name === "Saubiole");

  return (
    <div>
      <BannerPage>
        <div>
          <h2>Réduit</h2>
          <h3>Histoire et photos des rues, monuments et ponts du petit Bayonne</h3>
        </div>
      </BannerPage>
      <div className="riverain-container">
        <div className="image-container2">
          <img src="https://petitbayonne18852000.files.wordpress.com/2022/12/135-bayonne-bayonne-place-reduit.jpg" alt="Place du Réduit Bayonne" className="riverain-image" />
          <figcaption>Place du Réduit</figcaption>
        </div>
        <div className='apropos-content'>
          <h4>Place du RÉDUIT</h4>
          <p className='histoire'>
          Anciens noms : <strong>Porte et tour Saint Esprit – Porte de France – en 1906 on trouve une rue du Réduit</strong> (emplacement non défini)<br /> <br />
          Changement de numérotation en 1855.<br /><br />
          Le réduit est dessiné par Vauban en 1680 et construit à la fin du XVIIe siècle (tout comme la citadelle de Bayonne), à la demande de Louis XIV qui désirait fortifier la ville de Bayonne. La porte de France, de style classique, fut achevée vers 1760. Un premier niveau offre des colonnes antiques à pilastres, au second niveau, une niche abrita la statue de Louis XV jusqu’en 1789. Le président de la République Armand Fallières approuva la loi de déclassement de la place forte en juin 1907. La municipalité de Léo Pouzac se félicita de la destruction du Réduit car la circulation bayonnaise se trouverait ainsi considérablement améliorée et supprime l’octroi. La porte de France fut démontée en 1907, les pierres de l’édifice servirent à surélever l’esplanade sur laquelle fut érigée la statue du cardinal Lavigerie en juillet 1909.<br /> <br />
          La porte de France a été retrouvée enterrée sur place et remontée à la Poterne.
          <br /><br />
          Le 12 février 1937, l’échauguette s’effondre dans les flots de l’Adour.
          </p>
        </div>
      </div>
      <div className="navigation-buttons">
        {/* Afficher le bouton seulement si l'élément correspondant existe */}
        {pontriquePage && saubiolePage && (
          <div className="riverain-buttons">
            <div className="riverain-item" onClick={() => handlePageClick(pontriquePage.link)}>
              <Link to={pontriquePage.link}>Page précédente</Link>
            </div>
            <div className="riverain-item" onClick={() => handlePageClick(saubiolePage.link)}>
              <Link to={saubiolePage.link}>Page suivante</Link>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};
export default Reduit;