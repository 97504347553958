import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import BannerPage from '../../../components/BannerPage';
import Footer from '../../../components/Footer';
import '../../../App.css';
import ruesData from '../../../data/rues.json';

const Trinquet = () => {
  const navigate = useNavigate();
  
  const handlePageClick = (link) => {
    navigate(link);
  };

  // Filtrer le tableau ruesData pour récupérer uniquement l'élément correspondant à "Bert"
  const tonneliersPage = ruesData.find((page) => page.name === "Tonneliers");
  const visitandinesPage = ruesData.find((page) => page.name === "Visitandines");

  return (
    <div>
      <BannerPage>
        <div>
          <h2>Trinquet</h2>
          <h3>Histoire et photos des rues, monuments et ponts du petit Bayonne</h3>
        </div>
      </BannerPage>
      <div className="riverain-container">
        <div className="image-container2">
          <img src="https://petitbayonne18852000.files.wordpress.com/2022/12/le-trinquet-saint_andre.webp" alt="Trinquet St André" className="riverain-image" />
          <figcaption>Trinquet St André</figcaption>
        </div>
        <div className='apropos-content'>
          <h4>Rue du Trinquet</h4>
          <p className='histoire'>
          Anciens noms : <strong>rue Maubec – rue du Jeu de Paume</strong><br /> <br />
          Changement de numérotation en 1855.<br />   <br />
          Le Trinquet actuel existait déjà au 16ème siècle sous forme de jeu de paume. La porte chanfreinée et une fenêtre qui subsistent, appartiennent à la construction d’origine.<br /> <br />
          </p>
        </div>
      </div>
      <div className="navigation-buttons">
        {/* Afficher le bouton seulement si l'élément correspondant existe */}
        {tonneliersPage && visitandinesPage && (
          <div className="riverain-buttons">
            <div className="riverain-item" onClick={() => handlePageClick(tonneliersPage.link)}>
              <Link to={tonneliersPage.link}>Page précédente</Link>
            </div>
            <div className="riverain-item" onClick={() => handlePageClick(visitandinesPage.link)}>
              <Link to={visitandinesPage.link}>Page suivante</Link>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};
export default Trinquet;