import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import BannerPage from '../../../components/BannerPage';
import Footer from '../../../components/Footer';
import '../../../App.css';
import ruesData from '../../../data/rues.json';

const Saubiole = () => {
  const navigate = useNavigate();
  
  const handlePageClick = (link) => {
    navigate(link);
  };

  // Filtrer le tableau ruesData pour récupérer uniquement l'élément correspondant à "Bert"
  const reduitPage = ruesData.find((page) => page.name === "Réduit");
  const tonneliersPage = ruesData.find((page) => page.name === "Tonneliers");

  return (
    <div>
      <BannerPage>
        <div>
          <h2>Saubiole</h2>
          <h3>Histoire et photos des rues, monuments et ponts du petit Bayonne</h3>
        </div>
      </BannerPage>
      <div className="riverain-container">
        <div className='apropos-content'>
          <h4>Rue SAUBIOLE</h4>
          <p className='histoire'>
          Ancien nom : <strong>rue de la Flamande</strong>(marchande de blé)<br /> <br />
          Changement de numérotation en 1855.<br />   <br />
          De Sauviola ou de Sauvin ou Dérivé de Salvius ou Selva (la forêt) ?<br /> <br />
          Au 16e S corporation des potiers d’étain – 1715 atelier de fonte de graisse de baleine
          </p>
        </div>
      </div>
      <div className="navigation-buttons">
        {/* Afficher le bouton seulement si l'élément correspondant existe */}
        {reduitPage && tonneliersPage && (
          <div className="riverain-buttons">
            <div className="riverain-item" onClick={() => handlePageClick(reduitPage.link)}>
              <Link to={reduitPage.link}>Page précédente</Link>
            </div>
            <div className="riverain-item" onClick={() => handlePageClick(tonneliersPage.link)}>
              <Link to={tonneliersPage.link}>Page suivante</Link>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Saubiole;