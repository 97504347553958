import React from 'react';
import BannerPage from '../components/BannerPage';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../App.css';
import Footer from '../components/Footer';

const Aujourdhui = () => {

  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    prevArrow: (
      <div>
        <div className="prev-slick-arrow">
          <svg xmlns="http://www.w3.org/2000/svg" stroke="black" height="24" viewBox="0 -960 960 960" width="24">
            <path d="m242-200 200-280-200-280h98l200 280-200 280h-98Zm238 0 200-280-200-280h98l200 280-200 280h-98Z"/>
          </svg>
        </div>
      </div>
    ),
    nextArrow: (
      <div>
        <div className="next-slick-arrow">
          <svg xmlns="http://www.w3.org/2000/svg" stroke="black" height="24" viewBox="0 -960 960 960" width="24">
            <path d="m242-200 200-280-200-280h98l200 280-200 280h-98Zm238 0 200-280-200-280h98l200 280-200 280h-98Z"/>
          </svg>
        </div>
      </div>
    ),
  };

  return (
    <div>
      <BannerPage>
        <h2>Bayonne de nos jours</h2>
      </BannerPage>
      <div className="side-by-side">
        <div className="carousel-container">
          <h3>Carrousel de photos :</h3>
          <Slider {...settings}>
            <div>
              <img
                src="https://www.naiz.eus/media/asset_publics/resources/000/840/033/media_highlight/guz_foire_jambons_bobedme102.jpeg"
                alt="First slide"
                className="d-block w-100"
              />
            </div>
            <div>
              <img
                src="https://cf.bstatic.com/xdata/images/hotel/max1024x768/241499078.jpg?k=cef4eaf517be8ec504e8159dfd3666a01d6a80b9f7dff30abdab1a00d33db3d7&o=&hp=1"
                alt="Second slide"
                className="d-block w-100"
              />
            </div>
            <div>
              <img
                src="https://www.voyageavecnous.fr/wp-content/uploads/2022/08/que-faire-a-bayonne-marche-.jpg"
                alt="Third slide"
                className="d-block w-100"
              />
            </div>
            <div>
              <img
                src="https://media.sudouest.fr/3068256/1200x-1/so-5b59dd0666a4bdb169b04b7e-ph0.jpg?v=1532616128"
                alt="Fourth slide"
                className="d-block w-100"
              />
            </div>
            <div>
              <img
                src="https://www.guide-du-paysbasque.com/_bibli/articlesPage_rte/205/rte_images/fresque-sismikazot2.jpg"
                alt="Fifth slide"
                className="d-block w-100"
              />
            </div>
            <div>
              <img
                src="https://www.communaute-paysbasque.fr/fileadmin/_processed_/0/f/csm_Eva_Mena_PDV_2020_-_M.Prat_42_69b28a4149.jpg"
                alt="Sixth slide"
                className="d-block w-100"
              />
            </div>
            <div>
              <img
                src="https://media.sudouest.fr/11795922/1200x-1/20220728193526-p1239961.jpg"
                alt="Seventh slide"
                className="d-block w-100"
              />
            </div>
            <div>
              <img
                src="https://www.bayonne.fr/fileadmin/_processed_/4/0/csm_14_terrasses_places_rues_%C3%A9t%C3%A9_2021___M.Prat_4f175c37b2.jpg"
                alt="Eighth slide"
                className="d-block w-100"
              />
            </div>
            <div>
              <img
                src="https://www.bayonne.fr/fileadmin/_processed_/3/0/csm_aire_de_jeux_Poterne_-_1___Mathieu_Prat_e0078389e6.jpg"
                alt="Ninth slide"
                className="d-block w-100"
              />
            </div>
          </Slider>
        </div>
        <div className="map-container">
          <h3>Plan de Bayonne:</h3>
          <iframe 
            title="Plan de Bayonne, France"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2831.4437518282255!2d-1.4750012843478412!3d43.49333717151328!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd5527fb4609c8bf%3A0x8412b9f34488ee44!2sBayonne%2C%20France!5e0!3m2!1sen!2sus!4v1643333693925!5m2!1sen!2sus" 
            width="600" 
            height="450" 
            style={{ border: "0" }} 
            allowFullScreen=""
            loading="lazy" 
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
          <p>Vous pouvez utiliser les fonctionnalités de zoom directement sur la page consultée.</p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Aujourdhui;













