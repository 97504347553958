import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import BannerPage from '../../../components/BannerPage';
import Footer from '../../../components/Footer';
import '../../../App.css';
import ruesData from '../../../data/rues.json';

const Lesca = () => {
  const navigate = useNavigate();
  
  const handlePageClick = (link) => {
    navigate(link);
  };

  // Filtrer le tableau ruesData pour récupérer uniquement l'élément correspondant à "Bert"
  const laffitePage = ruesData.find((page) => page.name === "Laffite et Musée BONAT-HELLEU");
  const lissesPage = ruesData.find((page) => page.name === "Lisses");

  return (
    <div>
      <BannerPage>
        <div>
          <h2>Lesca</h2>
          <h3>Histoire et photos des rues, monuments et ponts du petit Bayonne</h3>
        </div>
      </BannerPage>
      <div className="riverain-container">
        <div className="image-container2">
          <img src="https://petitbayonne18852000.files.wordpress.com/2022/11/images.jpg" alt="Pierre esca" className="riverain-image" />
          <figcaption>Pierre Lesca</figcaption>
        </div>
        <div className='apropos-content'>
          <h4>Rue Pierre LESCA</h4>
          <p className='histoire'>
          De la rue des Cordeliers à la rue Pelletier<br /> <br />
          Pierre Lesca, Poète Gascon est né à Bayonne le 4 septembre 1730, dans la rue des Cordeliers, décédé en 1807<br /><br />
          Son père, Nicolas, et lui sont maîtres-tonnelier au 6/8 rue du Trinquet (ex. Maubec).<br /> <br />
          Rares sont les chanteurs gascons qui n’ont pas repris à un moment ou un autre Los Tilholèrs, chanson au rythme lancinant, interprétée sur un mode mineur, qui dispute à la plus tardive Salut Baiona ! le titre d’hymne gascon bayonnais.
          <br /><br />
          Une plaque commémorative est apposée au 23 rue des Cordeliers.
          </p>
        </div>
      </div>
      <div className="navigation-buttons">
        {/* Afficher le bouton seulement si l'élément correspondant existe */}
        {laffitePage && lissesPage && (
          <div className="riverain-buttons">
            <div className="riverain-item" onClick={() => handlePageClick(laffitePage.link)}>
              <Link to={laffitePage.link}>Page précédente</Link>
            </div>
            <div className="riverain-item" onClick={() => handlePageClick(lissesPage.link)}>
              <Link to={lissesPage.link}>Page suivante</Link>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};


export default Lesca;