import React, { useState, useEffect } from 'react';
import Footer from '../components/Footer';
import lexiqueData from '../data/lexique.json';
import '../App.css';
import BannerPage from '../components/BannerPage';

const Lexique = () => {

  const [sortedData, setSortedData] = useState([]);
  const [selectedLetter, setSelectedLetter] = useState(null);

  useEffect(() => {
    const sortData = () => {
      const sorted = [...lexiqueData.lexiqueData].sort((a, b) =>
        a.métier.localeCompare(b.métier, 'fr', { sensitivity: 'base' })
      );
      setSortedData(sorted);
    };

    sortData();
  }, []);

  const handleSort = () => {
    const sorted = [...lexiqueData.lexiqueData].sort((a, b) =>
      a.métier.localeCompare(b.métier, 'fr', { sensitivity: 'base' })
    );
    setSortedData(sorted);
    setSelectedLetter(null);
  };

  const handleLetterClick = (letter) => {
    const filteredData = lexiqueData.lexiqueData.filter(
      (item) => item.métier.charAt(0).toUpperCase() === letter
    );
    setSortedData(filteredData);
    setSelectedLetter(letter);
  };

  return (
    <div>
      <BannerPage>
        <h2>Lexique des métiers d'antan</h2>
        <button onClick={handleSort}>Trier par ordre alphabétique</button>
        <div className="alphabet-container">
          {Array.from({ length: 26 }, (_, index) => (
            <button
              key={index}
              className={`alphabet-letter ${
                selectedLetter === String.fromCharCode(65 + index) ? 'selected' : ''
              }`}
              onClick={() => handleLetterClick(String.fromCharCode(65 + index))}
            >
              {String.fromCharCode(65 + index)}
            </button>
          ))}
        </div>
      </BannerPage>

      <div className="lexique-container">
        <table className="lexique-table">
          <thead>
            <tr>
              <th>Métier</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {sortedData.map((item, index) => (
              <tr key={index}>
                <td>{item.métier}</td>
                <td>{item.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      
      <Footer />
    </div>
  );
};

export default Lexique;








