import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import BannerPage from '../../../components/BannerPage';
import Footer from '../../../components/Footer';
import '../../../App.css';
import monumentsData from '../../../data/monuments.json';

const Ppannecau = () => {
  const navigate = useNavigate();
  
  const handlePageClick = (link) => {
    navigate(link);
  };

  // Filtrer le tableau ruesData pour récupérer uniquement l'élément correspondant à "Bert"
  const pontMayouPage = monumentsData.find((page) => page.name === "Pont Mayou");
  const squareGabrielPage = monumentsData.find((page) => page.name === "Square Gabriel Léo Pouzacq");

  return (
    <div>
      <BannerPage>
        <div>
          <h2>PONT PANNECAU</h2>
          <h3>Histoire et photos des rues, monuments et ponts du petit Bayonne</h3>
        </div>
      </BannerPage>
      <div className="riverain-container">
        <div className="image-container2">
          <img src="https://petitbayonne18852000.files.wordpress.com/2022/11/plongeon-pont-pannecau.jpg" alt="Plongeon pont Pannecau" className="riverain-image" />
          <figcaption>Plongeon pont Pannecau</figcaption>
        </div>
        <div className='apropos-content'>
          <h4>PONT PANNECAU</h4>
          <p className='histoire'>
          Ancien nom :<strong> Pont Bertaco</strong><br /> <br />
          Le premier pont Bertaco ou Pannecau aurait été construit vers 1120, si ce n’est avant. Détruit par les mêmes crues que le Pont- Mayou, il est systématiquement rétabli. En 1729, en 1732 et en 1755, le pont est interdit à la circulation avant d’être refait en 1782 et restauré en 1831. Démoli en 1867, il est reconstruit en maçonnerie sous Napoléon III.<br />   <br />
          Un poste de garde et de péage, attesté au 13ème siècle, occupe l’angle du pont Pannecau et du quai des Cordeliers.<br /> <br />
          Selon François Lafitte Houssat, « […] « une ordonnance municipale de 1327 prévoyait l’enfermement de toute femme querelleuse et de mauvaise vie dans une cage de fer plongée dans l’eau de la Nive depuis le pont. » La pratique perdura jusque vers 1780, elle portait le nom évocateur de Cubainhade ou Cubainhedey. On y pratiquait le jeu de l’oie.
          </p>
        </div>
      </div>
      <div className="navigation-buttons">
        {/* Afficher le bouton seulement si l'élément correspondant existe */}
        {pontMayouPage && squareGabrielPage && (
          <div className="riverain-buttons">
            <div className="riverain-item" onClick={() => handlePageClick(pontMayouPage.link)}>
              <Link to={pontMayouPage.link}>Page précédente</Link>
            </div>
            <div className="riverain-item" onClick={() => handlePageClick(squareGabrielPage.link)}>
              <Link to={squareGabrielPage.link}>Page suivante</Link>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};


export default Ppannecau;