import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import BannerPage from '../../../components/BannerPage';
import Footer from '../../../components/Footer';
import '../../../App.css';
import ruesData from '../../../data/rues.json';

const Bourgneuf = () => {
  const navigate = useNavigate();
  
  const handlePageClick = (link) => {
    navigate(link);
  };

  // Filtrer le tableau ruesData pour récupérer uniquement l'élément correspondant à "Bert"
  const boufflersPage = ruesData.find((page) => page.name === "Boufflers");
  const chahoPage = ruesData.find((page) => page.name === "Chaho");

  return (
    <div>
      <BannerPage>
        <div>
          <h2>Bourgneuf</h2>
          <h3>Histoire et photos des rues, monuments et ponts du petit Bayonne</h3>
        </div>
      </BannerPage>
      <div className="riverain-container">
        <div className="image-container2">
          <img src="https://1.bp.blogspot.com/-gGj1RIM6m_Q/WQmUQ29lstI/AAAAAAAAGs8/I8dmCMu6pKQVgOektZvSYuq4uH9ONlRLgCLcB/s1600/FARNIE.JPG" alt="Bourgneuf 1900" className="riverain-image" />
          <figcaption>Bourgneuf 1900</figcaption>
        </div>
        <div className='apropos-content'>
          <h4>Rue BOURGNEUF</h4>
          <p className='histoire'>
          Anciens noms : <strong>Bourg-neuf – rue St . André – rue des Jacobins – Bourg Neu</strong><br /><br />
          Le Petit Bayonne est un quartier populaire et actif, qui fut concédé aux évêques en 1152 comme zone franche. Vauban l’engloba dans ses fortifications. Le quartier disposait de nombreux canaux qui servaient de voies de communication.<br />   <br />
          Sous le Consulat, la sous-Préfecture est au 23 de la rue Bourgneuf, au bâtiment occupé précédemment par l’Amirauté. Le tribunal de l’Amirauté, créé à la fin du 17ème siècle, se réunit à la Douane de la place Gramont qui lui prête ses locaux en 1700. Il est transféré au 23 rue Bourgneuf où elle occupe une maison double dénommée «Portonique».<br /> <br />
          Ont disparus les rues Bouheben et Chambourg donnant sur la rue Bourgneuf
          <br /><br />
          Au Moyen-Age, trois chantiers navals existent au Bourgneuf : celui du «Clos-des-galées» devant les Cordeliers, celui de «Borc-Nau» à l’actuel quai des Corsaires, le troisième étant devant les Jacobins à partir de 1317. Ce dernier, jouxtant la «Fosse aux mâts» et les allées Boufflers, subsiste jusqu’en 1854. Ceux du «Clos-des-galées» et du «Borc-Nau», figurent au plan de 1612, le second occupant l’emplacement de la maison Dagorette. Plusieurs chantiers se développent aussi à Mousserolles
          </p>
        </div>
      </div>
      <div className="navigation-buttons">
        {/* Afficher le bouton seulement si l'élément correspondant existe */}
        {boufflersPage && chahoPage && (
          <div className="riverain-buttons">
            <div className="riverain-item" onClick={() => handlePageClick(boufflersPage.link)}>
              <Link to={boufflersPage.link}>Page précédente</Link>
            </div>
            <div className="riverain-item" onClick={() => handlePageClick(chahoPage.link)}>
              <Link to={chahoPage.link}>Page suivante</Link>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};



export default Bourgneuf;