import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import BannerPage from '../../../components/BannerPage';
import Footer from '../../../components/Footer';
import '../../../App.css';
import ruesData from '../../../data/rues.json';

const Arsenal = () => {
  const navigate = useNavigate();

  const handlePageClick = (link) => {
    navigate(link);
  };

  // Filtrer le tableau ruesData pour récupérer uniquement l'élément correspondant à "Bastiat"
  const bastiatPage = ruesData.find((page) => page.name === "Bastiat");

  return (
    <div>
      <BannerPage>
        <div>
          <h2>Arsenal</h2>
          <h3>Histoire et photos des rues, monuments et ponts du petit Bayonne</h3>
        </div>
      </BannerPage>
      <div className="riverain-container">
        <div className="image-container2">
          <img src="https://petitbayonne18852000.files.wordpress.com/2022/11/place-de-larsenal.jpg" alt="PLACE DE L’ ARSENAL Bayonne" className="riverain-image" />
          <figcaption>PLACE DE L’ARSENAL Bayonne</figcaption>
        </div>
        <div className='apropos-content'>
          <h4>Place de l’ARSENAL</h4>
          <p className='histoire'>
            Anciens noms : <strong>Dépendance des Cordeliers – Sainte Claire – Retranchement Sainte Claire – rue de Menous </strong><br /> <br />
            Construite sur le Couvent des Cordeliers<br />   <br />
            Lieu de construction, d’entretien et d’armement des navires de guerre ou dépôt de munitions.<br /> <br />
            Changement de numérotations en 1855
          </p>
        </div>
      </div>
      <div className="navigation-buttons">
        {/* Afficher le bouton seulement si l'élément Bastiat existe */}
        {bastiatPage && (
          <div className="riverain-buttons">
            <div className="riverain-item" onClick={() => handlePageClick(bastiatPage.link)}>
              <Link to={bastiatPage.link}> Page suivante</Link>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Arsenal;










