import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import BannerPage from '../../../components/BannerPage';
import Footer from '../../../components/Footer';
import '../../../App.css';
import ruesData from '../../../data/rues.json';

const Corsaires = () => {
  const navigate = useNavigate();
  
  const handlePageClick = (link) => {
    navigate(link);
  };

  // Filtrer le tableau ruesData pour récupérer uniquement l'élément correspondant à "Bert"
  const cordeliersPage = ruesData.find((page) => page.name === "Cordeliers");
  const coursicPage = ruesData.find((page) => page.name === "Coursic");

  return (
    <div>
      <BannerPage>
        <div>
          <h2>Corsaires</h2>
          <h3>Histoire et photos des rues, monuments et ponts du petit Bayonne</h3>
        </div>
      </BannerPage>
      <div className="riverain-container">
        <div className="image-container2">
          <img src="https://www.musee-basque.com/files/standard/public/p5_9c0061ffae74ab6dd1274c306e37e1ecE.4310-2.jpg" alt="Maison Dagourette" className="riverain-image" />
          <figcaption>Maison Dagourette</figcaption>
        </div>
        <div className='apropos-content'>
          <h4>Quai des CORSAIRES</h4>
          <p className='histoire'>
          Anciens noms : <strong>quai de l’Amiral – quai de l’Entrepôt (1854 à 1920)</strong><br /> <br />
          Changement de numérotation en 1855<br />   <br />
          <strong>Maison Dagourette XVIeme Siecle</strong> au 37 quai des Corsaires. Sept religieuses résident à la maison Castelnau de Saint-Esprit avant d’être transférées à la maison Dagourette. Ancien Hôpital, puis grossiste en grains et tissus. Devenu « Musée Basque et de l’histoire de Bayonne » en 1924 Cette maison de caractère datant du XVIème siècle (classée monument historique en 1991) Le musée abrite une importante collection ethnographique consacrée<br /> <br />
          </p>
        </div>
      </div>
      <div className="navigation-buttons">
        {/* Afficher le bouton seulement si l'élément correspondant existe */}
        {cordeliersPage && coursicPage && (
          <div className="riverain-buttons">
            <div className="riverain-item" onClick={() => handlePageClick(cordeliersPage.link)}>
              <Link to={cordeliersPage.link}>Page précédente</Link>
            </div>
            <div className="riverain-item" onClick={() => handlePageClick(coursicPage.link)}>
              <Link to={coursicPage.link}>Page suivante</Link>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};



export default Corsaires;