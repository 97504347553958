import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import BannerPage from '../../../components/BannerPage';
import Footer from '../../../components/Footer';
import '../../../App.css';
import monumentsData from '../../../data/monuments.json';

const Esplanade = () => {
  const navigate = useNavigate();
  
  const handlePageClick = (link) => {
    navigate(link);
  };

  // Filtrer le tableau ruesData pour récupérer uniquement l'élément correspondant à "Bert"
  const saintAndrePage = monumentsData.find((page) => page.name === "Eglise Saint André");
  const pontGeniePage = monumentsData.find((page) => page.name === "Pont du Génie");

  return (
    <div>
      <BannerPage>
        <div>
          <h2>Esplanade Roland BARTHE</h2>
          <h3>Histoire et photos des rues, monuments et ponts du petit Bayonne</h3>
        </div>
      </BannerPage>
      <div className="riverain-container">
        <div className="image-container2">
          <img src="https://www.babelio.com/users/AVT_Roland-Barthes_6493.jpg" alt="Roland Barthes" className="riverain-image" />
          <figcaption>Roland Barthes</figcaption>
        </div>
        <div className='apropos-content'>
          <h4>Esplanade Roland BARTHE</h4>
          <p className='histoire'>
          Né le 12 novembre 1915 à Cherbourg et mort le 26 mars 1980 à Paris, est un philosophe, critique littéraire et sémiologue français.<br /> <br />
          Directeur d’études à l’École des hautes études en sciences sociales (EHESS) et professeur au Collège de France, il est l’un des principaux animateurs du poststructuralisme et de la sémiologie linguistique et photographique en France.<br />   <br />
          Il passe son enfance à Bayonne jusqu’en 1924.<br /><br />
          Sur l’emplacement du couvent des Cordeliers, des Mineurs ou Franciscains
          <br /><br />
          L’implantation de l’ordre des Cordeliers (aussi dénommé ordre des Franciscains ou ordre de Saint François ou ordre des Frères Mineurs) à Bayonne remonte aux années 1240. En effet, les Cordeliers reçoivent en 1242 le droit d’utiliser un oratoire établi sur les bords de la Nive. Mais, c’est en 1283 qu’ils obtiennent du roi d’Angleterre la concession d’un vaste terrain accompagné d’un legs en argent, l’ensemble destiné à entreprendre la construction d’un couvent et d’une église à proximité du Bourgneuf, développement urbain de Bayonne au XIIe siècle sur la rive de la Nive.<br /><br /> L’établissement était-il achevé au début du XIVe siècle lorsque les droits de sépultures dans le cimetière du couvent des Cordeliers sont contestés par l’évêque de Bayonne en 1307 ? Sans doute, puisque le couvent est incendié en 1333. Peut être reconstruit ou seulement réparé, il est inondé par une crue de la Nive au XVIIe siècle, pour subir à nouveau le feu en 1811, époque où les bâtiments avaient été transformés en hôpital militaire depuis 1808. L’ensemble est finalement détruit en 1833 lors de l’agrandissement de l’Arsenal.
          </p>
        </div>
      </div>
      <div className="navigation-buttons">
        {/* Afficher le bouton seulement si l'élément correspondant existe */}
        {saintAndrePage && pontGeniePage && (
          <div className="riverain-buttons">
            <div className="riverain-item" onClick={() => handlePageClick(saintAndrePage.link)}>
              <Link to={saintAndrePage.link}>Page précédente</Link>
            </div>
            <div className="riverain-item" onClick={() => handlePageClick(pontGeniePage.link)}>
              <Link to={pontGeniePage.link}>Page suivante</Link>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};



export default Esplanade;