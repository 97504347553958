import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import APropos from "./pages/APropos";
import Riverains from "./pages/Riverains";
import Histoire from "./pages/Histoire";
import Aujourdhui from "./pages/Aujourdhui";
import ClassementAlpha from "./pages/ClassementAlpha";
import Lexique from "./pages/Lexique";
import Arsenal from './pages/sous-pages/rues/Arsenal';
import Bastiat from './pages/sous-pages/rues/Bastiat';
import Bert from './pages/sous-pages/rues/Bert';
import Boufflers from './pages/sous-pages/rues/Boufflers';
import Bourgneuf from './pages/sous-pages/rues/Bourgneuf';
import Chaho from './pages/sous-pages/rues/Chaho';
import Charcutiere from './pages/sous-pages/rues/Charcutiere';
import Cordeliers from './pages/sous-pages/rues/Cordeliers';
import Corsaires from './pages/sous-pages/rues/Corsaires';
import Coursic from './pages/sous-pages/rues/Coursic';
import Galuperie from './pages/sous-pages/rues/Galuperie';
import Laffite from './pages/sous-pages/rues/Laffite';
import Lesca from './pages/sous-pages/rues/Lesca';
import Lisses from './pages/sous-pages/rues/Lisses';
import Marengo from './pages/sous-pages/rues/Marengo';
import Marsan from './pages/sous-pages/rues/Marsan';
import Mousserolles from './pages/sous-pages/rues/Mousserolles';
import Pannecau from './pages/sous-pages/rues/Pannecau';
import Pelletier from './pages/sous-pages/rues/Pelletier';
import Pontrique from './pages/sous-pages/rues/Pontrique';
import Reduit from './pages/sous-pages/rues/Reduit';
import Saubiole from './pages/sous-pages/rues/Saubiole';
import Tonneliers from './pages/sous-pages/rues/Tonneliers';
import Trinquet from './pages/sous-pages/rues/Trinquet';
import Visitandines from './pages/sous-pages/rues/Visitandines';

import Neuf from './pages/sous-pages/monuments/Neuf';
import Andre from './pages/sous-pages/monuments/SaintAndre';
import Esplanade from './pages/sous-pages/monuments/Esplanade';
import Genie from './pages/sous-pages/monuments/Genie';
import Pmarengo from './pages/sous-pages/monuments/Pmarengo';
import Pmayou from './pages/sous-pages/monuments/Pmayou';
import Ppannecau from './pages/sous-pages/monuments/Ppannecau';
import Square from './pages/sous-pages/monuments/Square';


export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="a-propos" element={<APropos />} />
          <Route path="riverains" element={<Riverains />} />
          <Route path="histoire" element={<Histoire />} />
          <Route path="aujourdhui" element={<Aujourdhui />} />
          <Route path="classement-alpha" element={<ClassementAlpha />} />
          <Route path="lexique" element={<Lexique />} />
          <Route path="arsenal" element={<Arsenal />} />
          <Route path="bastiat" element={<Bastiat />} />
          <Route path="bert" element={<Bert />} />
          <Route path="boufflers" element={<Boufflers />} />
          <Route path="bourgneuf" element={<Bourgneuf />} />
          <Route path="chaho" element={<Chaho />} />
          <Route path="charcutiere" element={<Charcutiere />} />
          <Route path="cordeliers" element={<Cordeliers />} />
          <Route path="corsaires" element={<Corsaires />} />
          <Route path="coursic" element={<Coursic />} />
          <Route path="galuperie" element={<Galuperie />} />
          <Route path="laffite-musee-bonat-helleu" element={<Laffite />} />
          <Route path="lesca" element={<Lesca />} />
          <Route path="lisses" element={<Lisses />} />
          <Route path="marengo" element={<Marengo />} />
          <Route path="marsan" element={<Marsan />} />
          <Route path="mousserolles-ravignan" element={<Mousserolles />} />
          <Route path="pannecau" element={<Pannecau />} />
          <Route path="pelletier" element={<Pelletier />} />
          <Route path="pontrique" element={<Pontrique />} />
          <Route path="reduit" element={<Reduit />} />
          <Route path="saubiole" element={<Saubiole />} />
          <Route path="tonneliers" element={<Tonneliers />} />
          <Route path="trinquet" element={<Trinquet />} />
          <Route path="visitandines" element={<Visitandines />} />
          <Route path="chateau-neuf" element={<Neuf />} />
          <Route path="eglise-saint-andre" element={<Andre />} />
          <Route path="esplanade-roland-barthe" element={<Esplanade />} />
          <Route path="pont-du-genie" element={<Genie />} />
          <Route path="pont-marengo" element={<Pmarengo />} />
          <Route path="pont-mayou" element={<Pmayou />} />
          <Route path="pont-pannecau" element={<Ppannecau />} />
          <Route path="square-gabriel-leo-pouzacq" element={<Square />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}



